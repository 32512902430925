import { Currency } from 'common/types';
import * as EmailValidator from 'email-validator';
import { Moment } from 'moment';
import * as WalletValidator from 'wallet-address-validator';

export const required = (value: any) => {
    if (value === null || value === undefined || value === false || value === '') {
        return 'error.validation_required';
    }

    return undefined;
};

export const maxLength = (value: string, maxValueLength: number) => {
    if (value && value.length > maxValueLength) {
        return {
            key: 'error.max_length_exceeded',
            options: {
                maxValueLength,
            },
        };
    }

    return undefined;
};

export const email = (value: string) => {
    if (!EmailValidator.validate(value)) {
        return 'error.validation_email';
    }

    return undefined;
};

export const minAmount = (min: number, currency: string = '') => {
    return (value: any) => {
        if (parseFloat(value) < min) {
            return {
                key: 'error.validation_min_amount',
                options: {
                    minAmount: min,
                    currency,
                },
            };
        }

        return undefined;
    };
};

export const maxAmount = (max: number, currency: string = '') => {
    return (value: any) => {
        if (parseFloat(value) > max) {
            return {
                key: 'error.validation_max_amount',
                options: {
                    maxAmount: max,
                    currency,
                },
            };
        }

        return undefined;
    };
};

export const amountLimits = (min: number, max: number, currency: string = '') => (value: any) => {
    return maxAmount(max, currency)(value) || minAmount(min, currency)(value);
};

const supportedCryptoCurrencies = [Currency.BTC, Currency.NANO, Currency.XMR, Currency.LTC, Currency.XRP];
const skippedCryptoCurrencies = [Currency.XVG, Currency.XZC, Currency.EOS, Currency.ICX, Currency.RVN, Currency.BEAM, Currency.BNB, Currency.BCH, Currency.ATOM, Currency.TRX, Currency.ZRX, Currency.XLM, Currency.XMR, Currency.WAXP];

export const cryptoAddress = (currency: string) => {
    return (value: string) => {
        if (skippedCryptoCurrencies.includes(currency as Currency)) {
            return undefined;
        }

        if (!WalletValidator.validate(value, supportedCryptoCurrencies.includes(currency as Currency) ? currency : Currency.ETH)) {
            return {
                key: 'error.validation_crypto_address',
                options: {
                    currency,
                },
            };
        }

        return undefined;
    };
};

export const isInt = (value: string) => {
    if (!/^[0-9]\d*$/.test(value)) {
        return 'error.not_a_integer';
    }

    return undefined;
};

export const isNumber = (value: string) => {
    if (!value) {
        return undefined;
    }
    if (!/^[-]?[0-9]\d*$/.test(value)) {
        return 'Value must be integer number';
    }

    return undefined;
};

export const isPositiveNumberLowerThan = (number: number) => (value: string) => {
    if (!value) {
        return undefined;
    }
    if (!/^[0-9]\d*$/.test(value) || Number.parseInt(value) > number) {
        return `Only integers between 0-${number}`;
    }
    return undefined;
}

export const positiveDecimal = (value: string) => {
    if (!value) {
        return undefined;
    }
    if (/^0{2,}\d*(\.\d*)?$/.test(value) || !/^(\d+(\.?\d{0,16}))$/.test(value) || (Number.parseInt(value) >= 10e12 &&  Number.parseFloat(`0.${value.split('.')[1]}`) > 0)) {
        return `Only 0 - 10e+12 with max 16 dec. points`;
    }


    return undefined;
}

export const positiveDecimalMoreThanZero = (value: string) => {
    if (!value) {
        return undefined;
    }
    if (/^0{2,}\d*(\.\d*)?$/.test(value) || !/^(\d+(\.?\d{0,16}))$/.test(value) || (Number.parseInt(value) >= 10e12 &&  Number.parseFloat(`0.${value.split('.')[1]}`) > 0)) {
        return `Only 0 - 10e+12 with max 16 dec. points`;
    }

    if (Number.parseFloat(value) == 0) {
        return `Must be more than 0`;
    }


    return undefined;
}

export const maxStringLength = (maxValueLength: number) => (value: string) => {
    if (value && value.length > maxValueLength) {
        return `Max ${maxValueLength} symbols`
    }

    return undefined;
};

export const isValidSymbols = (value: string) => {
    if (/(\\|}|{|`|\[|\]|#|\|)/.test(value)) {
        return 'error.not_valid_symbol';
    }

    return undefined;
};

export const isToRequired  = (val: Moment, toDate: Moment) =>
    !val && toDate ? 'error.validation_required' : undefined;
export const isBeforeTo  = (val: Moment, toDate: Moment) =>
    toDate?.isBefore(val) ? 'error.validation_from_date' : undefined;
export const isFromRequired  = (val: Moment, fromDate: Moment) =>
    !val && fromDate ? 'error.validation_required' : undefined;
export const isAfterFrom  = (val: Moment, fromDate: Moment) =>
    fromDate?.isAfter(val) ? 'error.validation_to_date' : undefined


export const validCoinString = (value: string) => {
    if(!value) {
        return undefined;
    }
    if (!/^([A-Z0-9-]+)$/.test(value)) {
        return 'Only A-Z and 0-9 symbols and “-”'
    }
    return undefined;
}

export const validCoinStringWithDot = (value: string) => {
    if(!value) {
        return undefined;
    }
    if (!/^([A-Z0-9-.]+)$/.test(value)) {
        return 'Only A-Z and 0-9 symbols and “-, .”'
    }
    return undefined;
}


export const validString = (value: string) => {
    if(!value) {
        return undefined;
    }
    if (!/^([A-Za-z0-9-]+)$/.test(value)) {
        return 'Only A-Z, a-z and 0-9 symbols and “-”'
    }
    return undefined;
}

export const validAlternativeName = (value: string) => {
    if(!value) {
        return undefined;
    }
    if (!/^([A-Z0-9\-\_]+)$/.test(value)) {
        return 'Only A-Z and 0-9 symbols and “-, _”'
    }
    return undefined;
}

export const validWallet = (value: string) => {
    if(!value) {
        return undefined;
    }
    if (!/^([A-Za-z0-9-.:_]+)$/.test(value)) {
        return 'Only A-Z, a-z and 0-9 symbols and . - : _'
    }
    return undefined;
}

export const requiredField = (value: any) => {
    if (value === null || value === undefined || value === false || value === '') {
        return 'Field is required';
    }

    return undefined;
};
