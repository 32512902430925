import { Dispatch } from 'redux';
import { routerActions } from 'connected-react-router';
import { initialize } from 'redux-form';

import { notificationsActions } from 'common/lib/notifications';
import { formatError, parseAmount } from 'common/lib/formatter';
import { isMainPage, getPartnerName } from 'common/lib/utils';
import * as api from 'client/api';
import { OfferAction, OfferDetailsFormData } from 'client/types';
import { BUY_CRYPTOCURRENCY_FORM, BuyCryptocurrencyFormData } from 'client/pages/teaser';


const createOffer = (
    pair: string,
    action: OfferAction,
    giveAmount: string | number,
    getAmount: string | number,
    userWallet?: string,
    userMemo?: string,
) => (dispatch: Dispatch) => {
    const amount = action === OfferAction.GIVE ? giveAmount : getAmount;
    const baseUrl = isMainPage() ? '/' : `/partners/${getPartnerName()}/`;
    const params = (userWallet != null && userMemo != null) ? `?wallet=${userWallet}&memo=${userMemo}` : '';
    return api.createOffer(pair, action, typeof amount === 'string' ? parseAmount(amount) : amount, userWallet, userMemo)
        .then(response => {
            dispatch(routerActions.push(`${baseUrl}offer/${response.data.id}${params}`));
        })
        .catch(response => {
            dispatch(routerActions.push(baseUrl));
            dispatch(notificationsActions.showNotificationError(formatError(response)));
        });
};

const editOffer = (pair: string, action: OfferAction, giveAmount: string, getAmount: string, userWallet?: string, userMemo?: string) => (dispatch: Dispatch) => {
    const initialData = {
        pair,
        action,
        giveAmount: OfferAction.GIVE === action ? giveAmount : null,
        getAmount: OfferAction.GET === action ? getAmount : null,
    } as BuyCryptocurrencyFormData;

    const params = (userWallet != null && userMemo != null) ? `?wallet=${userWallet}&memo=${userMemo}` : '';
    const baseUrl = isMainPage() ? '/' : `/partners/${getPartnerName()}${params}`;
    dispatch(routerActions.push(baseUrl));
    dispatch(initialize(BUY_CRYPTOCURRENCY_FORM, initialData));
};

const confirmOffer = (offerId: string, values: OfferDetailsFormData) => (dispatch: Dispatch) => {
    return api.confirmOffer(offerId, values)
        .then(response => {
            const baseUrl = isMainPage() ? '/' : `/partners/${getPartnerName()}/`;
            const params = (values.cryptoWalletAddress != null && values.identifier != null) ? `?wallet=${values.cryptoWalletAddress}&memo=${values.identifier}` : '';
            dispatch(routerActions.push(`${baseUrl}payment-details/${response.data.id}${params}`));
        })
        .catch(response => {
            dispatch(notificationsActions.showNotificationError(formatError(response)));
        });
};

export default {
    createOffer,
    editOffer,
    confirmOffer,
};
