import { AxiosResponse } from 'axios';
import * as queryString from 'query-string';

import RestService from 'common/rest-service';
import {
    ConfirmOfferModel,
    CreateOfferModel,
    OfferAction,
    OfferModel,
    PairsPricesModel,
    PairsHistoryPricesModel,
    PaymentModel,
    TeaserModel,
    UserModel,
    StartVerificationModel,
    FinishVerificationModel,
    SimplexPaymentData, VoltBankModel, Country, PaymentRedirectModel, OfferDetailsFormData, RapydBankModel,
} from 'client/types';
import { getPartnerName, isMainPage } from 'common/lib/utils';

const restService = new RestService('client');

export function getPairsPrices(fiat: string): Promise<AxiosResponse<PairsPricesModel>> {
    let url = `/app/v3/price?fiat=${fiat}`;
    if (!isMainPage()) {
        url += `&partner=${getPartnerName()}`;
    }
    return restService.get(url);
}

export function getPairPriceHistory(): Promise<AxiosResponse<PairsHistoryPricesModel>> {
    return restService.get('/app/v2/price/history?period=WEEK');
}

export function getUser(): Promise<AxiosResponse<UserModel>> {
    return restService.get('/app/v2/users/me');
}

export function logout() {
    return restService.delete('/app/v2/sessions');
}

export function sendMagicLink(
    email: string,
    pair: string,
    action: OfferAction,
    amount: number,
    recaptcha: string,
): Promise<AxiosResponse<void>> {
    return restService.post(`/app/v2/magic/link?g-recaptcha-response=${recaptcha}`, {
        email,
        pair,
        action,
        amount,
        type: 'CREATE_OFFER',
    });
}

export function sendLoginCode(
    email: string,
    pair: string,
    action: OfferAction,
    amount: number,
    recaptcha: string,
    userWallet?: string,
    userMemo?: string,
): Promise<AxiosResponse<void>> {
    return restService.post(`/app/v2/code-login/code?g-recaptcha-response=${recaptcha}`, {
        email,
        pair,
        action,
        amount,
        type: 'CREATE_OFFER',
        userWallet,
        userMemo
    });
}

export function loginWithCode(
    email: string,
    code: string,
): Promise<AxiosResponse<TeaserModel>> {
    return restService.post(`/app/v2/code-login`,
        queryString.stringify({
            email,
            code,
        }),
    );
}

export function getTeaserDetails(token: string): Promise<AxiosResponse<TeaserModel>> {
    return restService.post(
        '/app/v2/magic/token',
        {},
        {
            headers: {
                'MAGIC-TOKEN': token,
            },
        },
    );
}

export function createOffer(pair: string, action: OfferAction, amount: number, userWallet?: string, userMemo?: string): Promise<AxiosResponse<CreateOfferModel>> {
    return restService.post('/app/v2/offer',
        { pair, action, amount, type: 'CREATE_OFFER', partner: getPartnerName(), userWallet, userMemo }
        );
}

export function getOffer(offerId: string): Promise<AxiosResponse<OfferModel>> {
    return restService.get(`/app/v2/offer/${offerId}`);
}

export function confirmOffer(offerId: string, values: OfferDetailsFormData): Promise<AxiosResponse<ConfirmOfferModel>> {
    return restService.post(`/app/v2/offer/${offerId}?action=accept`,
        { cryptoWallet: values.cryptoWalletAddress, identifier: values.identifier,
            paymentMethod: values.paymentMethod,
            voltBankId: values.paymentMethod === 'VOLT' ? values.bank : null,
            country: values.paymentMethod === 'RAPYD' ? values.country : null});
}

export function getPayment(paymentId: string): Promise<AxiosResponse<PaymentModel>> {
    return restService.get(`/app/v2/payments/${paymentId}`);
}

export function voltPayment(paymentId: string): Promise<AxiosResponse<PaymentRedirectModel>> {
    return restService.post(`/app/v2/volt-payments/${paymentId}/pay`);
}

export function getCountries(paymentMethod: string): Promise<AxiosResponse<Array<Country>>> {
    return restService.get(`/app/classifiers/countries?method=${paymentMethod}`);
}

export function getVoltBanks(country: string): Promise<AxiosResponse<Array<VoltBankModel>>> {
    return restService.get(`/app/v2/volt-payments/banks?country=${country}`);
}

export function rapydPayment(paymentId: string): Promise<AxiosResponse<PaymentRedirectModel>> {
    return restService.post(`/app/v2/rapyd-payments/${paymentId}/pay`);
}

export function getRapydCountries(): Promise<AxiosResponse<Array<Country>>> {
    return restService.get(`/app/v2/rapyd-payments/countries`);
}

export function getRapydBanks(country: string, currency: string): Promise<AxiosResponse<Array<RapydBankModel>>> {
    return restService.get(`/app/v2/rapyd-payments/methods?country=${country}&fiat=${currency}`);
}

export function getSimplexPaymentData(paymentId: string): Promise<AxiosResponse<SimplexPaymentData>> {
    return restService.post(`/app/v2/payments/${paymentId}/pay`);
}

export function startVerification(paymentDetailsId: string): Promise<AxiosResponse<StartVerificationModel>> {
    return restService.post('/app/v2/kyc', {
        paymentDetailsId,
    });
}

export function finishVerification(token: string): Promise<AxiosResponse<FinishVerificationModel>> {
    return restService.post(`/app/v2/kyc?token=${token}`);
}

export function subscribeEmail(email: string): Promise<AxiosResponse<any>> {
    return restService.post('/app/v2/email-subscription', { email });
}
