import * as React from 'react';
import classnames from 'classnames';

import { getPartnerName } from 'common/lib/utils';

import * as styles from './label.scss';

interface Props {
    children: React.ReactChild,
}

const className = classnames(
    styles['.label'],
    !getPartnerName() && styles[`label-${getPartnerName()}`],
);

export default (props: Props) => {

    return (
        <div className={className}>{props.children}</div>
    );
};
