import * as React from 'react';
import {withNamespaces, WithNamespaces} from 'react-i18next';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {routerActions} from 'connected-react-router';
import {Link} from 'react-router-dom';

import {getPartnerName} from 'common/lib/utils';
import Button from 'common/lib/button';
import Typography from 'common/lib/typography';

import * as styles from './partner-teaser-continue-component.scss';

interface DispatchProps {
    actions: {
        router: typeof routerActions,
    },
}

interface OwnProps {
    url?: string
    userMemo?: string
    userWallet?: string
}

type Props = OwnProps & WithNamespaces & DispatchProps;

const PartnersTeaserContinueComponent = (props: Props) => {

    if (props.url === undefined) {
        return (<div></div>);
    }

    const { t, url } = props;

    const redirectToMainPage = () => {
        const {
            userWallet,
            userMemo,
        } = props;

        let params;

        if (!!!userMemo && !!!userWallet) {
            params = (userWallet != null && userMemo != null) ? `?wallet=${userWallet}&memo=${userMemo}` : '';
        }
        props.actions.router.push(`/partners/${getPartnerName()}${params}`);
    };

    return (
        <div>
            <div className={styles['text-box']}>
                <Typography type="text" color="gray">
                    <strong>{t('partner.note')}</strong> {t('partner.login_continue_text')}
                </Typography>
            </div>

            <Link onClick={redirectToMainPage} to={url} target="_blank">
                <Button text={t('partner.continue')} block/>
            </Link>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        router: bindActionCreators(routerActions, dispatch),
    },
});

export default withNamespaces()(connect(null, mapDispatchToProps)(PartnersTeaserContinueComponent));
