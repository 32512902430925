import classnames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ErrorBoundary from "client/components/error-boundary";
import KycPage from "client/pages/kyc";
import { JumpingDots } from "common/lib/loaders";
import Notifications from "common/lib/notifications";
import { getPartnerName, isDomainUs, isMainPage } from "common/lib/utils";

import * as styles from "./app.scss";

const TeaserPage = React.lazy(
  () => import(/* webpackChunkName: "teaser-page" */ "client/pages/teaser")
);
const WidgetPage = React.lazy(
  () => import(/* webpackChunkName: "widget-page" */ "client/pages/widget")
);
const OfferPage = React.lazy(
  () => import(/* webpackChunkName: "offer-page" */ "client/pages/offer")
);
const PaymentDetailsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "payment-details-page" */ "client/pages/payment-details"
    )
);
const SuccessPurchasePage = React.lazy(
  () => import(/* webpackChunkName: "magic-page" */ "client/pages/payment-info")
);
const VoltPayment = React.lazy(
  () => import(/* webpackChunkName: "magic-page" */ "client/pages/volt-payment")
);
const RapydPayment = React.lazy(
  () =>
    import(/* webpackChunkName: "magic-page" */ "client/pages/rapyd-payment")
);
const AboutPage = React.lazy(
  () => import(/* webpackChunkName: "about-page" */ "client/pages/about")
);
const PartnershipsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "partnerships-page" */ "client/pages/partnerships"
    )
);
const LegalPages = React.lazy(
  () => import(/* webpackChunkName: "legal-page" */ "client/pages/legal")
);
const MagicPage = React.lazy(
  () => import(/* webpackChunkName: "magic-page" */ "client/pages/magic")
);

const PartnerTeaserPage = React.lazy(
  /* webpackChunkName: "partner-teaser-page" */ async () => ({
    default: (await import("client/pages/teaser")).Partner,
  })
);

const PartnerOfferPage = React.lazy(
  /* webpackChunkName: "partner-offer-page" */ async () => ({
    default: (await import("client/pages/offer")).Partner,
  })
);

const App = () => {
  useEffect(() => {
    let title;
    let script = document.createElement("script");
    script.setAttribute("src", "https://consent.cookiebot.com/uc.js");
    script.setAttribute("id", "Cookiebot");
    script.setAttribute("data-blockingmode", "auto");
    script.setAttribute("type", "text/javascript");

    if (isDomainUs()) {
      script.setAttribute("data-cbid", "52d2862a-31f7-4c19-971c-e6733f30f4cc"); //US
      title =
        "Nuvei US LLC | Buy Bitcoin | Cryptocurrencies with euro instantly";
    } else {
      title = "Nevadaex | Buy Bitcoin | Cryptocurrencies with euro instantly";
      script.setAttribute("data-cbid", "52d2862a-31f7-4c19-971c-e6733f30f4cc"); //EU
    }

    document.title = title;
    document.head.appendChild(script);
  }, []);

  const classNames = classnames(
    styles["app"],
    !isMainPage() && styles[`app-${getPartnerName()}`]
  );

  return (
    <>
      <div className={classNames}>
        <Notifications />

        <div className={styles["content"]}>
          <ErrorBoundary hasError={false}>
            <React.Suspense
              fallback={
                <div className={styles["loader"]}>
                  <JumpingDots dark />
                </div>
              }
            >
              <Switch>
                <Route path="/" exact component={TeaserPage} />
                <Route path="/magic/:token" exact component={MagicPage} />
                <Route path="/offer/:offerId" exact component={OfferPage} />
                <Route path="/widget/:partner" exact component={WidgetPage} />
                <Route
                  path="/payment-details/:paymentId"
                  exact
                  component={PaymentDetailsPage}
                />
                <Route
                  path="/payment-success/:paymentId"
                  exact
                  component={SuccessPurchasePage}
                />
                <Route path="/payment/volt" exact component={VoltPayment} />
                <Route
                  path="/payment/rapyd/:status"
                  exact
                  component={RapydPayment}
                />
                <Route path="/kyc" exact component={KycPage} />
                <Route path="/about" exact component={AboutPage} />
                <Route
                  path="/partnerships"
                  exact
                  component={PartnershipsPage}
                />
                <Route path="/legal" component={LegalPages} />
                <Route
                  path="/partners/:partner/"
                  exact
                  component={PartnerTeaserPage}
                />
                <Route
                  path="/partners/:partner/login/:step/:email"
                  exact
                  component={PartnerTeaserPage}
                />
                <Route
                  path="/partners/:partner/offer/:offerId"
                  exact
                  component={PartnerOfferPage}
                />
                <Route
                  path="/partners/:partner/payment-details/:paymentId"
                  exact
                  component={PaymentDetailsPage}
                />
                <Redirect to="/" />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default App;
