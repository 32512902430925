import { Dispatch } from 'redux';

import { CHANGE_FIAT } from './constants';

const change = (val: string) => (dispatch: Dispatch) => {
    dispatch({type: CHANGE_FIAT, payload: val});
};

export default {
    change
};
