import { SortBy, UserWithdrawalStatus } from 'admin/types';
import { AxiosResponse } from 'axios';
import { Pair } from 'client/types';

import { SortDirection, UserVerificationStatus } from 'common/types';
import * as currencyFormatter from 'currency-formatter';
import { t } from 'i18next';
import * as moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

interface FormatDateOptions {
    includeTime: boolean,
}

export const formatDate = (date: string, options: FormatDateOptions = { includeTime: false }) => {
    if (!date) {
        return undefined;
    }

    return moment(date).format(options.includeTime ? DATE_TIME_FORMAT : DATE_FORMAT);
};

export const formatToUpperCase = (str: string) => {
    if (!str) {
        return undefined;
    }

    return str.toUpperCase();
};

const AMOUNT_FORMATTING_OPTIONS = {
    precision: 5,
    decimal: '.',
    thousand: ',',
};

interface FormatAmountOptions {
    precision?: number,
    currency?: string,
    withSign?: boolean,
}

export const formatAmount = (amount: number, options: FormatAmountOptions = {}): string => {
    if (amount === null || amount === undefined) {
        return undefined;
    }

    const {
        currency = '',
        precision = 5,
        withSign = false,
    } = options;

    const sign = (
        withSign && amount > 0
    ) ? '+' : '';
    const formattedAmount = currencyFormatter.format(amount, AMOUNT_FORMATTING_OPTIONS);
    const [thousand, decimal] = formattedAmount.split('.');

    return `${sign}${thousand}.${decimal.substr(0, precision)} ${currency}`.trim();
};

export const parseAmount = (amount: string): number => {
    if (!amount) {
        return undefined;
    }

    return currencyFormatter.unformat(amount, AMOUNT_FORMATTING_OPTIONS);
};

export const parsePair = (pair: string): Pair => {
    if (pair) {
        const [
            giveCurrency,
            getCurrency,
        ] = pair.split('/');

        return {
            giveCurrency,
            getCurrency,
        } as Pair;
    }

    return {} as Pair;
};

export const formatError = (response: AxiosResponse, skipCode: boolean = false) => {
    if (response && response.data) {
        if (response.data.code === 'VALIDATION_FAILED'){
            return response.data.message;
        }
        if (!skipCode && response.data.code) {
            return t(`error.${response.data.code}`);
        }

        if (response.data.message) {
            return t(response.data.message);
        }
    }

    return t('error.UNEXPECTED_ERROR');
};

export const formatName = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }

    if (firstName) {
        return firstName;
    }

    if (lastName) {
        return lastName;
    }

    return '';
};

export const removeWhitespaces = (value: string) => {
    if (value) {
        return value.replace(/\s+/g, '');
    }

    return '';
};

export const formatUserVerificationStatus = (userVerificationStatus: UserVerificationStatus) => {
    if (!userVerificationStatus) {
        return null;
    }

    return t(`verification.${userVerificationStatus}`);
};

export const formatUserWithdrawalStatus = (userWithdrawalStatus: UserWithdrawalStatus) => {
    if (!userWithdrawalStatus) {
        return null;
    }

    return t(`withdrawal.${userWithdrawalStatus}`);
};

export const getSortDirection = (sortBy: Array<SortBy>): SortDirection | string => {
    return sortBy.length === 0
        ? ''
        : sortBy[0].desc ? SortDirection.DESC : SortDirection.ASC;
};

export const getSortField = (sortBy: Array<SortBy>): string | null => {
    return sortBy.length === 0
        ? ''
        : sortBy[0].id;
};

export const enumToOptionsList = (Enum: { [key: string]: string }, exclude?: string[]) =>
    Object.keys(Enum)
        .filter(key => {
            if (exclude) {
                return !exclude.includes(key);
            }
            return true;
        })
        .map(key => (
        {
            label: Enum[key],
            value: key,
        }
    ));

export const arrayToOptionsList = (array: Array<string>) => {
    if (array) {
        return array.map(value => ({
            label: value.replaceAll('_', ' '),
            value,
        }));
    }
    return [];
}

export function camelToSentenceCase(str: string) {
    return str.split(/([A-Z]|\d)/).map((v, i, arr) => {
        // If first block then capitalise 1st letter regardless
        if (!i) return v.charAt(0).toUpperCase() + v.slice(1);
        // Skip empty blocks
        if (!v) return v;
        // Underscore substitution
        if (v === '_') return ' ';
        // We have a capital or number
        if (v.length === 1 && v === v.toUpperCase()) {
            const previousCapital = !arr[i - 1] || arr[i - 1] === '_';
            const nextWord = i + 1 < arr.length && arr[i + 1] && arr[i + 1] !== '_';
            const nextTwoCapitalsOrEndOfString = i + 3 > arr.length || !arr[i + 1] && !arr[i + 3];
            // Insert space
            if (!previousCapital || nextWord) v = ' ' + v;
            // Start of word or single letter word
            if (nextWord || (
                !previousCapital && !nextTwoCapitalsOrEndOfString
            )) v = v.toLowerCase();
        }
        return v;
    }).join('');
}

function isUpperCase(str: string) {
    return str === str.toUpperCase();
}

export function camelToTitleCase(str: string) {
    for (let i = str.length - 1; i > 0; i--) {
        if (!isUpperCase(str[i - 1]) && isUpperCase(str[i])) {
            str = str.slice(0, i) + ' ' + str.slice(i);
        }
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
