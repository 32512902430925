import {Currency, FailReason, OfferStatus, PaymentMethods, UserVerificationStatus} from 'common/types';

export interface PairModel {
    unitPrice: number,
    volume: number,
    maxAmount: number,
}

export interface CurrencyLimitModel {
    min: number,
    max: number,
}
export interface PairWrapperModel {
    priceList: Array<PairModel>,
    isInactive: boolean,
}
export interface PairsPricesModel {
    limits: {
        [currency: string]: CurrencyLimitModel,
    },
    prices: {
        [pair: string]: PairWrapperModel,
    },
    priceOrder: string[],
}

export interface PairsHistoryPricesModel {
    content: {
        [pair: string]: Array<PairModel>,
    },
}

export interface Pair {
    giveCurrency: Currency
    getCurrency: Currency
}

export interface UserModel {
    email: string,
}

export enum OfferAction {
    GIVE = 'GIVE',
    GET = 'GET',
}

export interface TeaserModel {
    email: string,
    payload: {
        pair: string,
        action: OfferAction,
        amount: number,
        email: string,
        type: 'CREATE_OFFER',
    },
    token: string,
    type: 'CREATE_OFFER'
}

export interface OfferModel {
    id: string,
    pair: string,
    give: number,
    get: number,
    action: OfferAction,
    unitPrice: number,
    status: OfferStatus,
    validTill: string,
}

export interface CreateOfferModel {
    id: string,
}

export interface ConfirmOfferModel {
    id: string,
}

export interface MoneyAmount {
    amount: number,
    currency: string,
}

export interface SimplexPaymentData {
    partnerName: string,
    version: string,
    paymentId: string,
    userId: string,
    email: string,
    fiatTotalAmount: MoneyAmount,
    firstName: string,
    lastName: string,
    phone: string,
    paymentFlowType: string,
    returnUrl: string,
    formSubmitUrl: string,

}

export interface PaymentRedirectModel {
    redirectUrl: string,
}

export interface PaymentModel {
    paymentMethod: PaymentMethods,
    customerData: {
        cryptoWalletAddress: string,
        bankName: string,
        bankCountry: string,
        email: string,
        identifier: string,
        userVerificationStatus: UserVerificationStatus,
        failReason: FailReason,
    },
    offerData: {
        offerId: string,
        amount: number,
        volume: number,
        pair: string,
    },
    vendorData: {
        bankAccountName: string,
        bankAccount: string,
        paymentReferenceNumber: string,
    },
}

export interface VoltBankModel {
    id: string,
    name: string,
    officialName: string,
    branchName: string,
    active: boolean,
    logo?: string
}

export interface RapydBankModel {
    type: string,
    name: string,
    image?: string,
}

export interface StartVerificationModel {
    kycUrl: string,
}

export interface FinishVerificationModel {
    paymentDetailsId: string,
}

export interface Country {
    name: string,
    alpha2: string,
    alpha3: string,
    iso_alpha2: string,
    iso_alpha3: string,
}

export interface OfferDetailsFormData {
    paymentMethod: PaymentMethods,
    cryptoWalletAddress: string,
    country?: string,
    bank?: string,
    identifier?: string,
}
