exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.notification-container__M-7AK .notification {\n  font-family: Ubuntu, serif;\n  font-size: 14px;\n  color: white;\n  padding: 16px;\n  align-items: center; }\n  .notification-container__M-7AK .notification .notification-body {\n    display: block;\n    margin: 0; }\n", "", {"version":3,"sources":["/usr/app/src/common/lib/notifications/components/notification-container.scss"],"names":[],"mappings":"AAaA;;;;;;EAME;AACF;;;;;;EAME;AACF;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,aAAa;EACb,cAAc;EACd,oBAAoB,EAAE;EACtB;IACE,eAAe;IACf,UAAU,EAAE","file":"notification-container.scss","sourcesContent":[":export {\n  background: #f3f4fa;\n  white: #FFFFFF;\n  gray10: #AAACC6;\n  gray20: #E0E2EC;\n  gray30: #F0F1F8;\n  red: #F75959;\n  blue-gray: #717391;\n  primary-color-cryptotraders: #241c4a;\n  secondary-color: #FECF2E;\n  primary-color: #2E304E;\n  secondary-color-changenow: #5C5780; }\n\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.notification-container :global .notification {\n  font-family: Ubuntu, serif;\n  font-size: 14px;\n  color: white;\n  padding: 16px;\n  align-items: center; }\n  .notification-container :global .notification .notification-body {\n    display: block;\n    margin: 0; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780",
	"notification-container": "notification-container__M-7AK"
};