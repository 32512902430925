import { toast, ToastType } from 'react-toastify';

function showNotification(message: string, type: any) {
    toast(message, {
        hideProgressBar: true,
        className: 'notification',
        bodyClassName: 'notification-body',
        closeButton: false,
        autoClose: 3000,
        type,
    });
}

function showNotificationError(message: string): any {
    return () => showNotification(message, ToastType.ERROR);
}

function showNotificationSuccess(message: string): any {
    return () => showNotification(message, ToastType.SUCCESS);
}

export default {
    showNotificationError,
    showNotificationSuccess,
};
