import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import LogoutIcon from 'common/lib/icons/logout.svg';
import { default as authActions } from '../actions';
import { State as UserState } from '../reducer';
import { getUser } from '../selectors';

import * as styles from './user-profile.scss';

interface StateProps {
    user: UserState,
}

interface DispatchProps {
    actions: {
        auth: typeof authActions,
    }
}

type Props = StateProps & DispatchProps;

class UserProfile extends React.Component<Props, null> {

    componentDidMount(): void {
        this.props.actions.auth.fetchUser();
    }

    render() {
        const {
            user: {
                user,
                loaded,
            },
            actions,
        } = this.props;

        if (!loaded && user == null) {
            return null;
        }

        return (
            <div className={styles['user-profile']}>
                <div className={styles['email']}>
                    {user.email}
                </div>
                <LogoutIcon className={styles['logout']} onClick={actions.auth.logout}/>
            </div>
        );
    };
}

const mapStateToProps = (state: any): StateProps => ({
    user: getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
