import * as React from 'react';
import {Trans, WithNamespaces, withNamespaces} from 'react-i18next';

import Typography from 'common/lib/typography';
import BackArrowIcon from './back-arrow.svg';
import LinkSentIcon from './link-sent.svg';

import * as styles from './link-sent-placeholder.scss';
import {StepType, UserSteps} from 'common/lib/user-steps';
import {AlertType, default as Alert} from 'common/lib/alert';
import { isIoDomain } from 'common/lib/utils';

interface OwnProps {
    email: string,
    onClickBack: () => void,
}

type Props = OwnProps & WithNamespaces;

const LinkSentPlaceholder = (props: Props) => {
    const {
        t,
        email,
        onClickBack,
    } = props;

    return (
        <div className={styles['link-sent-placeholder']}>
            <UserSteps step={StepType.EMAIL}/>
            <LinkSentIcon/>

            <div className={styles['title']}>
                <Typography type="title">{t('share_email.magic_link_sent')}</Typography>
            </div>
                <Typography type="subtitle">
                    <Trans i18nKey="share_email.click_and_continue" values={{email}}>
                        We just sent a confirmation link to <strong>{email}</strong>. Click the link and continue buying
                        your cryptocurrency.
                    </Trans>
                </Typography>
            <div className={styles['subtitle']}>
                <Alert type={AlertType.WARNING}>
                    <Typography type="subtitle">
                        <b>Important</b> If you do not receive the link within a few minutes, please check your Spam folder just in case the
                        email got delivered there. If so, click Not Spam, which will allow future messages to get through.
                    </Typography>
                </Alert>
            </div>

            <div className={styles['back-arrow-button']} onClick={onClickBack}>
                <BackArrowIcon/>
            </div>
        </div>
    );
};

export default withNamespaces()(LinkSentPlaceholder);
