import { handleActions } from 'redux-actions';

import { UserModel } from 'client/types';
import { FETCH_USER, FETCH_USER_SUCCESS, LOGOUT_USER } from './constants';

export interface State {
    user?: UserModel,
    loading: boolean,
    loaded: boolean,
}

function getInitialState(): State {
    return {
        user: null,
        loading: false,
        loaded: false,
    };
}

export const reducer = handleActions<State, any>(
    {
        [FETCH_USER]: (state): State => {
            return {
                ...state,
                loading: true,
            };
        },

        [FETCH_USER_SUCCESS]: (state, { payload }): State => {
            return {
                ...state,
                user: payload as UserModel,
                loading: false,
                loaded: true,
            };
        },

        [LOGOUT_USER]: getInitialState,
    },
    getInitialState(),
);
