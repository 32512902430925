import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { reducer as form, FormStateMap } from 'redux-form';
import { History } from 'history';

import { reducer as userReducer, State as UserState } from 'client/components/user';
import { reducer as pageReducer, State as PageState } from 'client/pages/teaser';

export interface RootState {
    form: FormStateMap,
    router?: RouterState,
    user: UserState,
    page: PageState,
}

export default (history: History) => combineReducers<RootState>({
    form,
    router: connectRouter(history),
    user: userReducer,
    page: pageReducer,
});
