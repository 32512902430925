import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';

import { default as FieldWrapper, OwnProps as FieldWrapperProps } from 'common/lib/fields/field-wrapper/field-wrapper';
import Typography from 'common/lib/typography';

import * as styles from './autocomplete.scss';


interface State {
    activeSuggestion: AutocompleteItem,
    // The suggestions that match the user's input
    filteredSuggestions: Array<AutocompleteItem>,
    // Whether or not the suggestion list is shown
    showSuggestions: boolean,
    // What the user has entered
    userInput: string,
    focused: boolean,
}

export interface AutocompleteItem {
    name: string,
    value: string,
    logoUrl?: string
}

interface OwnProps {
    suggestions: Array<AutocompleteItem>,
    placeHolder?: string,
    noSuggestionsText?: string,
    showItems?: boolean,
    selectedItem?: string,
    loadData?: (item: AutocompleteItem) => void,
    useImgLogo?: boolean
}

type Props = OwnProps & WrappedFieldProps & FieldWrapperProps;

export default class Autocomplete extends React.Component<Props, State> {


    public readonly state: State = {
        activeSuggestion: undefined,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: "",
        focused: false,
    };

    private wrapperRef: React.RefObject<HTMLInputElement> = null;
    private inputRef: React.RefObject<HTMLInputElement> = null;


    constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.filteredSuggestions = this.filteredSuggestions.bind(this);
        this.showFirstElements = this.showFirstElements.bind(this);
        this.hideElements = this.hideElements.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidMount(): void {

        const {suggestions, showItems, selectedItem} = this.props;
        let filteredSuggestions:AutocompleteItem[] = [];
        if (selectedItem !== undefined) {
            filteredSuggestions = suggestions.filter(
                suggestion =>
                    suggestion.value.toLowerCase().indexOf(selectedItem.toLowerCase()) > -1
            );
        }

        if (filteredSuggestions.length === 1) {
            this.setState({
                activeSuggestion: filteredSuggestions[0],
                filteredSuggestions,
                showSuggestions: filteredSuggestions.length !== 1,
                focused: filteredSuggestions.length !== 1,
                userInput: filteredSuggestions[0]['name']
            }, () => {
                document.removeEventListener('mousedown', this.handleClickOutside);
                if (filteredSuggestions.length === 1) {
                    this.props.loadData && this.props.loadData(filteredSuggestions[0] as AutocompleteItem);
                    this.props.input.onChange(filteredSuggestions[0]['value']);
                } else {
                    this.props.input.onChange(null);
                }
            });
        } else {
            this.setState({
                showSuggestions: showItems,
                focused: showItems
            });
        }
        if (this.props.showItems) {
            this.inputRef.current.focus();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.suggestions !== prevProps.suggestions) {
            this.setState({...this.state, userInput: ''})
        }
    }

    handleClickOutside(event: any) {
        const { suggestions } = this.props;
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            // Filter our suggestions that don't contain the user's input
            const filteredSuggestions = suggestions.filter(
                suggestion =>
                    suggestion.name.toLowerCase().indexOf(this.state.userInput.toLowerCase()) > -1
            );
            this.setState({
                ...this.state,
                showSuggestions: false,
                filteredSuggestions,
                activeSuggestion: filteredSuggestions.length > 1 ? null : filteredSuggestions[0],
                userInput: filteredSuggestions.length > 1 ? '' : filteredSuggestions[0]['name']
            }, () => {
                document.removeEventListener('mousedown', this.handleClickOutside);
                if (filteredSuggestions.length === 1) {
                    this.props.loadData && this.props.loadData(filteredSuggestions[0] as AutocompleteItem);
                    this.props.input.onChange(filteredSuggestions[0]['value']);
                } else {
                    this.props.input.onChange(null);
                }
            });
        }
    }

    onChange(e: any) {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            activeSuggestion: undefined,
            filteredSuggestions,
            showSuggestions: true,
            userInput: filteredSuggestions.length === 0 ? '' : e.currentTarget.value
        });
    };

    filteredSuggestions(text: string) {
        return this.props.suggestions.filter(
            suggestion =>
                suggestion.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
    }

    hideElements() {
        this.setState({
            ...this.state,
            filteredSuggestions: [],
            showSuggestions: false,
            focused: false,
        });
    }

    showFirstElements() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({
            ...this.state,
            filteredSuggestions: this.props.suggestions,
            showSuggestions: true,
            focused: true,
        });
    }

    onClick(e: any) {
        const text = e.currentTarget.innerText;
        const item = this.filteredSuggestions(text)[0];
        this.setState({
            activeSuggestion: item,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: text
        }, () => {
            const subStr = this.state.userInput.replace(/(\r\n|\n|\r)/gm, "");
            const loadedItem = this.filteredSuggestions(subStr)[0] as AutocompleteItem;
            this.props.loadData && this.props.loadData(loadedItem);
            this.props.input.onChange(loadedItem.value);
        });
    };

    onBlur (e: any) {
        console.warn(e.currentTarget.value)
    }

    onKeyDown (e: any) {
        const userInput = e.currentTarget.value;

        if (this.filteredSuggestions(userInput).length === 0) {
            this.setState({
                activeSuggestion: null,
                showSuggestions: false,
                userInput: '',
            }, () => {
                this.props.input.onChange(null);
            });
        } else {
            // User pressed the enter key
            if (e.keyCode === 13) {
                const item = this.filteredSuggestions(userInput)[0];
                this.setState({
                    activeSuggestion: item,
                    showSuggestions: false,
                    userInput: userInput,
                }, () => {
                    this.props.loadData && this.props.loadData(item);
                    this.props.input.onChange(item.value);
                });
            }
        }
    };

    render() {
        const {
            onChange,
            onKeyDown,
            state: {
                userInput,
            }
        } = this;
        const {
            input,
            meta,
            suffix,
            prefix,
            placeHolder,
            ...rest
        } = this.props;

        const suffixElement = (
            <span onClick={() => this.setState({...this.state, filteredSuggestions: []})}>{suffix}</span>
        );

        const noValue = userInput.length === 0;

        return (
            <span ref={this.wrapperRef}>
                <FieldWrapper input={input} meta={meta} {...rest} prefix={noValue ? prefix : null}
                              suffix={noValue ? suffixElement : null} disabled={false}>
                    <input
                        type="text"
                        {...input}
                        ref={this.inputRef}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={this.onBlur}
                        placeholder={placeHolder}
                        autoComplete={"off"}
                        onFocus={this.showFirstElements}
                        value={this.props.suggestions.length !== 0 ? userInput : ''}
                        className={styles['text-field']}
                    />
                </FieldWrapper>
                {this.renderOptions()}
            </span>
        );
    }

    renderOptions() {
        const {
            onClick,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
                focused,
            },
            props: {
                useImgLogo,
                suggestions,
                noSuggestionsText
            },
        } = this;


        let suggestionsListComponent;

        let showSuggestion = false;

        if (showSuggestions && userInput) {
            showSuggestion = true;
        }

        if (showSuggestions && focused) {
            showSuggestion = true;
        }

        if (suggestions.length === 0) {
            return (
                <div className={styles["no-suggestions"]}>
                    <Typography type="text">{noSuggestionsText}</Typography>
                </div>
            )
        }

        if (showSuggestion) {
            if (filteredSuggestions.length > 0) {
                suggestionsListComponent = (
                    <div className={styles["suggestions"]}>
                        <div className={styles["suggestion"]}>
                            {filteredSuggestions.map((suggestion, index) => {
                                let className = 'suggestion-not-active';

                                // Flag the active suggestion with a class
                                if (activeSuggestion != undefined) {
                                    if (suggestion.name === activeSuggestion.name) {
                                        className = 'suggestion-active';
                                    }
                                }

                                return (
                                    <div className={styles[className]} key={suggestion.value} onClick={onClick}>
                                        <Row>
                                            <Col lg={1}>
                                                {!useImgLogo &&
                                                <div style={{
                                                    backgroundImage: `url(${suggestion.logoUrl})`,
                                                    minWidth: "24px",
                                                    height: "18px",
                                                    backgroundRepeat: "no-repeat",
                                                }}></div>}
                                                {useImgLogo &&
                                                <img style={{
                                                    minWidth: "40px",
                                                    height: "25px",
                                                    paddingBottom: "5px",
                                                    backgroundRepeat: "no-repeat",
                                                }}
                                                     src={suggestion.logoUrl}/>
                                                }
                                            </Col>
                                            <Col lg={11}>
                                                <span>{suggestion.name}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
        }
        return suggestionsListComponent;
    }
}
