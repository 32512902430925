import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as queryString from 'query-string';

import { JumpingDots } from 'common/lib/loaders';
import { actions as kycActions } from 'client/pages/kyc';

import * as styles from './kyc-page.scss';

interface DispatchProps {
    actions: {
        kyc: typeof kycActions,
    },
}

type Props = DispatchProps & WithNamespaces & RouteComponentProps<any>;

class KycPage extends React.Component<Props> {
    componentDidMount() {
        const {
            actions,
            location: {
                search,
            },
        } = this.props;

        const {
            token,
        } = queryString.parse(search);

        actions.kyc.finishVerification(token as string);
    }

    render() {
        return (
            <div className={styles['kyc-page']}>
               <JumpingDots dark/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        kyc: bindActionCreators(kycActions, dispatch),
    },
});

export default withNamespaces()(connect(null, mapDispatchToProps)(KycPage));
