import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import * as copy from 'copy-to-clipboard';

import Popover from 'common/lib/popover';
import CopyIcon from 'common/lib/icons/copy.svg';
import ValidationError from './../partials/validation-error';
import Label from './../partials/label';

import * as styles from './field-wrapper.scss';

export interface OwnProps {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    copyable?: boolean;
    suffix?: React.ReactChild;
    prefix?: React.ReactChild;
    children?: React.ReactChild;
    big?: boolean;
    hideErrors?: boolean;
}

type Props = OwnProps & WrappedFieldProps;

class FieldWrapper extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.copyValue = this.copyValue.bind(this);
        this.renderSuffix = this.renderSuffix.bind(this);
        this.renderPrefix = this.renderPrefix.bind(this);
    }

    copyValue() {
        const {
            input,
        } = this.props;

        copy(input.value);
    }

    renderSuffix() {
        const {
            copyable,
            suffix,
        } = this.props;

        if (!suffix && !copyable) {
            return null;
        }

        // TODO translations
        if (copyable) {
            return (
                <div className={styles['suffix']}>
                    <Popover
                        text={'Copy to clipboard'}
                        clickedText={'Copied to clipboard'}
                    >
                        <CopyIcon className={styles['copy-button']} onClick={this.copyValue}/>
                    </Popover>
                </div>
            );
        }

        return (
            <div className={styles['suffix']}>
                {suffix}
            </div>
        );
    }

    renderPrefix() {
        const {
            prefix,
        } = this.props;

        if (!prefix) {
            return null;
        }

        return (
            <div className={styles['prefix']}>
                {prefix}
            </div>
        );
    }

    render() {
        const {
            label,
            meta,
            prefix,
            hideErrors = false,
            children,
        } = this.props;

        const hasError: boolean = meta.touched && !!meta.error;
        const className = classnames(
            styles['wrapper-field'],
            hasError && styles['has-error'],
        );

        return (
            <div className={className}>
                {label &&
                    <Label>{label}</Label>
                }
                <div className={!prefix ? styles['input'] : styles['input-prefix']}>
                    {this.renderPrefix()}
                    {children}
                    {this.renderSuffix()}
                </div>
                {hasError && !hideErrors &&
                    <ValidationError show={hasError} error={meta.error}/>
                }
            </div>
        );
    }
}

export default FieldWrapper;
