import * as React from 'react';

import * as styles from './user-steps.scss';
import {Col, Row} from 'react-flexbox-grid';
import Typography from 'common/lib/typography';
import PathIcon from './path.svg';
import * as classnames from "classnames";
import {getPartnerName, isMainPage} from 'common/lib/utils';

export enum Type {
    CRYPTO = 'crypto',
    EMAIL = 'email',
    METHOD = 'paymentMethod',
    VERIFICATION = 'verification',
    PAY = 'pay',
}

interface StateProps {
    step: Type,
}

type Props = StateProps;

export default (props: Props) => {

    let crypto = false, email = false, paymentMethod = false, verification = false, pay = false;

    if (props.step == Type.CRYPTO) {
        crypto = false;
    }

    if (props.step == Type.EMAIL) {
        crypto = true;
        email = false;
    }

    if (props.step == Type.METHOD) {
        crypto = true;
        email = true;
        paymentMethod = false;
    }

    if (props.step == Type.VERIFICATION) {
        crypto = true;
        email = true;
        paymentMethod = true;
        verification = false;
    }

    if (props.step == Type.PAY) {
        crypto = true;
        email = true;
        paymentMethod = true;
        verification = true;
        pay = false;
    }

    const stepsClassNames = classnames(
        styles['steps'],
        !isMainPage() && styles[`steps-${getPartnerName()}`],
    );


    const dotClassNames = classnames(
        styles['dot'],
        !isMainPage() && styles[`dot-${getPartnerName()}`],
    );

    const doneClassNames = classnames(
        styles['rectangle-done'],
        !isMainPage() && styles[`rectangle-done-${getPartnerName()}`],
    );

    function renderDot(show: boolean, shownBefore: boolean) {

        if (shownBefore && !show) {
            return (
                <div className={styles['rectangle-current']}>
                    <div className={dotClassNames}></div>
                </div>
            )
        }

        if (show) {
            return(
                <div className={doneClassNames}>
                    <PathIcon />
                </div>
            )
        } else {
            return (
                <div className={styles['rectangle-current']}></div>
            )
        }
    }


    return (
        <div className={styles['user-steps']}>
            <Row>
                <Col lg={1} sm={1} xs={1}></Col>
                <Col lg={3} sm={3} xs={3}>
                    <Typography type={"subtext"}>Choose crypto</Typography>
                </Col>
                <Col lg={1} sm={1} xs={1}></Col>
                <Col lg={3} sm={3} xs={3}>
                    <Typography type={"subtext"}>Payment Method</Typography>
                </Col>
                <Col lg={1} sm={1} xs={1}></Col>
                <Col lg={3} sm={3} xs={3}>
                    <Typography type={"subtext"}>Complete payment</Typography>
                </Col>
            </Row>


            <Row>
                <Col lg={2} sm={2} xs={2} className={styles['no-padding']}>
                    <div className={stepsClassNames}></div>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <Row>
                        <Col lg={4} sm={4} xs={5} className={styles['no-padding']}>
                            {renderDot(crypto, true)}

                        </Col>
                        <Col lg={8} sm={8} xs={7} className={styles['no-padding']}>
                            <div className={crypto ? stepsClassNames : styles['steps-next']}></div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <div className={crypto ? stepsClassNames : styles['steps-next']}></div>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <Row>
                        <Col lg={4} sm={4} xs={5} className={styles['no-padding']}>
                            {renderDot(email, crypto)}
                        </Col>
                        <Col lg={8} sm={8} xs={7} className={styles['no-padding']}>
                            <div className={email ? stepsClassNames : styles['steps-next']}></div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <div className={email ? stepsClassNames : styles['steps-next']}></div>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <Row>
                        <Col lg={4} sm={4} xs={5} className={styles['no-padding']}>
                            {renderDot(paymentMethod, email)}
                        </Col>
                        <Col lg={8} sm={8} xs={7} className={styles['no-padding']}>
                            <div className={paymentMethod ? stepsClassNames : styles['steps-next']}></div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <div className={paymentMethod ? stepsClassNames : styles['steps-next']}></div>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <Row>
                        <Col lg={4} sm={4} xs={5} className={styles['no-padding']}>
                            {renderDot(verification, paymentMethod)}
                        </Col>
                        <Col lg={8} sm={8} xs={7} className={styles['no-padding']}>
                            <div className={verification ? stepsClassNames : styles['steps-next']}></div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <div className={verification ? stepsClassNames : styles['steps-next']}></div>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <Row>
                        <Col lg={4} sm={4} xs={5} className={styles['no-padding']}>
                            {!pay && renderDot(pay, verification)}
                            {pay && <div className={doneClassNames}>
                                <PathIcon/>
                            </div>
                            }
                        </Col>
                        <Col lg={8} sm={8} xs={7} className={styles['no-padding']}>
                            <div className={pay ? stepsClassNames : styles['steps-next']}></div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} sm={1} xs={1} className={styles['no-padding']}>
                    <div className={pay ? stepsClassNames : styles['steps-next']}></div>
                </Col>
            </Row>

            <Row className={styles['steps-title-bottom']}>
                <Col lg={3} sm={3} xs={3}></Col>
                <Col lg={2} sm={2} xs={2} className={styles['bottom-element']}>
                    <Typography type={"subtext"}>Your email</Typography>
                </Col>
                <Col lg={2} sm={2} xs={2}></Col>
                <Col lg={4} sm={4} xs={4} className={classnames(styles['bottom-element'], styles['bottom-element-last'])}>
                    <Typography type={"subtext"}>Verification</Typography>
                </Col>
            </Row>
        </div>
    )
}