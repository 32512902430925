import * as React from 'react';
import classnames from 'classnames';

import * as styles from './jumping-dots.scss';

/*
    Base on: http://jsbin.com/lejawoji/1/edit?html,css,js,output
*/

interface Props {
    dark?: boolean,
    delay?: number,
    overlay?: boolean,
}

interface State {
    showLoader: boolean,
}

class JumpingDots extends React.Component<Props, State> {
    private timeoutId: NodeJS.Timeout = null;

    public readonly state: State = {
        showLoader: false,
    };

    static defaultProps = {
        delay: 100,
    };

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ showLoader: true });
        }, this.props.delay);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    render() {
        if (!this.state.showLoader) {
            return null;
        }
        const className = classnames(
            styles['jumping-dots'],
            this.props.dark && styles['dark'],
            this.props.overlay && styles['overlay'],
        );

        return (
            <div className={className}>
                <div className={styles['first-dot']}/>
                <div className={styles['second-dot']}/>
                <div className={styles['third-dot']}/>
            </div>
        );

    }
}

export default JumpingDots;
