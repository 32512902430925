import * as React from "react";
import { useEffect } from "react";
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import * as styles from './validation-error.scss';

interface Error {
    key: string,
    options?: object,
}

interface OwnProps {
    show: boolean,
    error: string | Error,
}

type Props = OwnProps & WithNamespaces;

export const ValidationError = (props: Props) => {
    const {
        error,
        t,
        show,
        i18n
    } = props;

    useEffect(() => {
        i18n.init({
            nsSeparator: false,
            keySeparator: false,
        })
    }, [])



    const getMessage = () => {
        if (typeof error === 'object') {
            return t(error.key, error.options) || error.key;
        }

        return t(error) || error;
    };

    return (
        <div className={styles['validation-error']}>
            <CSSTransition
                in={show}
                timeout={300}
                unmountOnExit
                classNames={{
                    enter: styles['message-enter'],
                    enterActive: styles['message-enter-active'],
                }}
            >
                <div className={styles['message']}>
                    {getMessage()}
                </div>
            </CSSTransition>
        </div>
    );
};

export default withNamespaces()(ValidationError);
