import * as React from 'react';
import classnames from 'classnames';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import FacebookIcon from 'common/lib/icons/facebook.svg';
import TwitterIcon from 'common/lib/icons/twitter.svg';
import LinkedinIcon from 'common/lib/icons/linkedin.svg';
import InstagramIcon from 'common/lib/icons/instagram.svg';
import { getDomain, isIoDomain, isMainPage } from 'common/lib/utils';

import * as styles from './footer.scss';

interface MediaProps {
    link: string,
    icon: React.ComponentType,
}

interface OwnProps {
    hideSocNetworks?: boolean,
}

const Media = (props: MediaProps) => {
    const {
        link,
        icon: Icon,
    } = props;

    return (
        <a href={link} target="_blank" rel="noopener" className={styles['social-network']}>
            <Icon/>
        </a>
    );
};

type Props = OwnProps & WithNamespaces;

const Footer = (props: Props) => {
    const {
        t,
    } = props;

    // const copyrightsClasses = classnames(
    //     styles['copyrights'],
    //     !isMainPage() && styles['full-width']);

    return (
        <div className={styles['footer']}>
            <div className={styles['address']}>
                {t(`global.nevada_${getDomain()}_address`)}
            </div>
            {/*{false &&*/}
            {/*<div className={styles['social-networks']}>*/}
            {/*    <div className={styles['socials']}>*/}
            {/*        <Media icon={LinkedinIcon} link="https://www.linkedin.com/company/elastum/"/>*/}
            {/*        <Media icon={TwitterIcon} link="https://twitter.com/elastumio"/>*/}
            {/*        <Media icon={FacebookIcon} link="https://www.facebook.com/elastum/"/>*/}
            {/*        <Media icon={InstagramIcon} link="https://www.instagram.com/elastum.io/"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*}*/}
        </div>
    );
};

export default withNamespaces()(Footer);
