import * as React from 'react';
import classnames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Typography from 'common/lib/typography/index';
import DropDown from 'common/lib/icons/dropdown.svg';
import { default as pageActions } from 'client/pages/teaser/actions'
import { State as PageState } from 'client/pages/teaser/reducer'
import { getPage } from 'client/pages/teaser/selectors'
import { Currency } from 'common/types/index';
import { isMainPage, getPartnerName } from 'common/lib/utils';

import * as styles from './drop-down-field.scss';

interface StateProps {
    page: PageState,
}

export interface OwnProps {
    values: Array<Currency>,
}

interface DispatchProps {
    actions: {
        page: typeof pageActions,
    }
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
    show: boolean,
    node: any,
}

class DropDownField extends React.Component<Props, State> {
    public readonly state: State = {
        show: false,
        node: React.createRef(),
    };

    constructor(props: Props) {
        super(props);
        this.show = this.show.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.select = this.select.bind(this);
        this.hide = this.hide.bind(this);
        this.handleClick = this.handleClick.bind(this);
        document.addEventListener('mousedown', this.handleClick);
    }

    handleClick(e: Event) {
        if (this.state.node.current !== null && !this.state.node.current.contains(e.target)) {
            this.hide();
        }
    }

    select(val: Currency) {
        if (val !== this.props.page.fiat) {
            this.props.actions.page.change(val);
        }
        this.setState({ show: false });
    }

    renderOptions() {

        return this.props.values.map(val => {
            return (<a key={val} onClick={() => this.select(val)}><Typography type='text'>{val}</Typography></a>);
        });
    }

    show() {
        this.setState({ ...this.state, show: !this.state.show, node: React.createRef() });
    }

    hide() {
        this.setState({ ...this.state, show: false });
    }

    render() {

        const contentClassNames = classnames(
            styles['drop-down-content'],
            !isMainPage() && styles[`button-${getPartnerName()}`],
            this.state.show && styles['show'],
        );

        const btnClassNames = classnames(
            styles['drop-down-btn'],
            this.state.show && styles['hide-styles'],
        )

        return (
            <div className={styles['drop-down']} ref={this.state.node}>
                <div onClick={() => this.show()} className={btnClassNames}>
                    <Row>
                        <Col lg={6} sm={6} xs={6} className={styles['drop-down-btn-text']}>
                            <Typography type='text'>{this.props.page.fiat}</Typography>
                        </Col>
                        <Col lg={6} sm={6} xs={6}>
                            <DropDown/>
                        </Col>
                    </Row>
                </div>
                <div id='drop-down-div' className={contentClassNames}>
                    {this.renderOptions()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        page: getPage(state)
    })
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        page: bindActionCreators(pageActions, dispatch),
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(DropDownField);
