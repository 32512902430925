import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import BackArrowIcon from './back-arrow.svg';

import Typography from 'common/lib/typography';
import {
    TextField,
    validations,
} from 'common/lib/fields';
import Button from 'common/lib/button';
import MagicStickIcon from 'common/lib/icons/magic-stick.svg';

import * as styles from './share-email-form.scss';
import {StepType, UserSteps} from 'common/lib/user-steps';

export interface ShareEmailFormData {
    email: string,
}

interface StateProps {
    formValues: ShareEmailFormData,
}

interface OwnProps {
    onClickBack: () => void,
    titleText: string,
    subtitleText: string,
    button: string,
    magic: boolean,
    onFormSubmit: (values: ShareEmailFormData, recaptcha: string) => Promise<any>,
}

type Props = WithNamespaces & StateProps & OwnProps;

interface State {
    url: string,
    recaptchaLoaded: boolean,
    recaptchaProcessing: boolean,
}

// @ts-ignore
const recaptchaSiteKey = RECAPTCHA_SITE_KEY;
const FORM_NAME = 'SHARE_EMAIL_FORM';

class ShareEmailForm extends React.Component<Props & InjectedFormProps<ShareEmailFormData, Props>, State> {
    public readonly state: State = {
        recaptchaLoaded: false,
        recaptchaProcessing: false,
        url: undefined,
    };

    public recaptcha: React.RefObject<ReCAPTCHA> = React.createRef();

    constructor(props: Props & InjectedFormProps<ShareEmailFormData, Props>) {
        super(props);

        this.submit = this.submit.bind(this);
        this.onRecaptchaTokenChanged = this.onRecaptchaTokenChanged.bind(this);
        this.onRecaptchaLoaded = this.onRecaptchaLoaded.bind(this);
    }

    onRecaptchaTokenChanged(recaptchaToken: string) {
        const {
            formValues,
        } = this.props;

        return this.props.onFormSubmit(formValues, recaptchaToken);
    }

    onRecaptchaLoaded() {
        this.setState({
            recaptchaLoaded: true,
        });
    }

    submit() {
        this.setState({
            recaptchaProcessing: true,
        });
        this.recaptcha.current.execute();
    }

    render() {
        const {
            recaptchaLoaded,
            recaptchaProcessing,
        } = this.state;

        const {
            handleSubmit,
            onClickBack,
            titleText,
            subtitleText,
            button,
            magic,
            t,
        } = this.props;

        return (
            <div className={styles['share-email-form']}>
                <UserSteps step={StepType.EMAIL}/>
                <div className={styles['title']}>
                    <Typography type="title">{titleText}</Typography>
                </div>
                <div className={styles['subtitle']}>
                    <Typography type="subtitle">{subtitleText}</Typography>
                </div>

                <Form onSubmit={handleSubmit(this.submit)}>
                    <Row>
                        <Col lg={12}>
                            <Field
                                name="email"
                                big
                                disabled={recaptchaProcessing}
                                component={TextField}
                                label={t('share_email.your_email')}
                                validate={[
                                    validations.required,
                                    validations.email,
                                ]}
                            />
                        </Col>
                    </Row>

                    <div className={styles['actions-row']}>
                        <Row className={styles['buttons']}>
                            <Col xs={12} sm={2}>
                                <div className={styles['back-arrow-button']}>
                                    <BackArrowIcon onClick={onClickBack}/>
                                </div>
                            </Col>

                            <Col xs={12} sm={7} md={6}>
                                <Button
                                    disabled={recaptchaProcessing || !recaptchaLoaded}
                                    text={button}
                                    icon={magic && MagicStickIcon}
                                    block
                                />
                            </Col>
                        </Row>
                    </div>
                </Form>

                <ReCAPTCHA
                    ref={this.recaptcha}
                    sitekey={recaptchaSiteKey}
                    size="invisible"
                    theme="light"
                    onChange={this.onRecaptchaTokenChanged}
                    asyncScriptOnLoad={this.onRecaptchaLoaded}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any): StateProps => ({
    formValues: getFormValues(FORM_NAME)(state) as ShareEmailFormData,
});

export default withNamespaces()(
    connect(mapStateToProps)(
        reduxForm<ShareEmailFormData, Props>({ form: FORM_NAME })(
            ShareEmailForm,
        ),
    ),
);
