import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Field, Form, InjectedFormProps, change, getFormValues, reduxForm, touch } from 'redux-form';

import { OfferAction, PairsHistoryPricesModel, PairsPricesModel } from 'client/types';
import Button from 'common/lib/button';
import CurrencyIcon from 'common/lib/currency-icon';
import { NumberField, RenderOptionProps, SelectOption, SelectOptions, validations } from 'common/lib/fields';
import { parseAmount, parsePair } from 'common/lib/formatter';
import MastercardIcon from 'common/lib/icons/mastercard-accepted.svg';
import SepaIcon from 'common/lib/icons/sepa-accepted.svg';
import VisaIcon from 'common/lib/icons/visa-accepted.svg';
import Separator from 'common/lib/separator';
import Typography from 'common/lib/typography';
import { StepType, UserSteps } from 'common/lib/user-steps';

import ArrowsIcon from './arrows.svg';
import CryptocurrencySelectOption from './cryptocurrency-select-option';
import DropDownField from './drop-down-field/drop-down-field';

import { isDomainUs } from 'common/lib/utils';
import { Currency } from 'common/types';
import * as styles from './buy-cryptocurrency-form.scss';

export interface BuyCryptocurrencyFormData {
    pair: string,
    giveAmount: string,
    getAmount: string,
    action: OfferAction,
}

interface OwnProps {
    pairs: PairsPricesModel,
    historyPairs: PairsHistoryPricesModel,
    showUserSteps: boolean,
}

interface StateProps {
    formValues: BuyCryptocurrencyFormData,
}

interface DispatchProps {
    actions: {
        form: {
            change: typeof change,
            touch: typeof touch,
        },
    },
}

type Props = OwnProps & StateProps & DispatchProps & WithNamespaces;

export const BUY_CRYPTOCURRENCY_FORM = 'BUY_CRYPTOCURRENCY_FORM';

const amountValidation = () => (value: string, allValues: BuyCryptocurrencyFormData, allProps: Props) => {
    const currency = parsePair(allValues.pair)['giveCurrency'];
    const currencyLimits = allProps.pairs.limits[allValues.pair];

    if (!currencyLimits) {
        return undefined;
    }

    return validations.amountLimits(currencyLimits.min, currencyLimits.max, currency)(parseAmount(value));
};

const giveAmountValidation = amountValidation();

class BuyCryptocurrencyForm extends React.Component<Props & InjectedFormProps<BuyCryptocurrencyFormData, Props>> {
    constructor(props: Props & InjectedFormProps<BuyCryptocurrencyFormData, Props>) {
        super(props);

        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.recalculateAmounts = this.recalculateAmounts.bind(this);
        this.renderFields = this.renderFields.bind(this);
    }

    componentDidUpdate() {
        this.recalculateAmounts();
    }

    recalculateAmounts() {
        const {
            pairs: {
                prices,
            },
            formValues: {
                pair,
                giveAmount,
                getAmount,
                action,
            },
            actions,
        } = this.props;

        if (prices[pair] !== undefined) {

            const selectedPairPriceList = prices[pair].priceList;

            if (action === OfferAction.GIVE) {
                const enteredAmount = parseAmount(giveAmount);
                const unitPrice = (
                    selectedPairPriceList.find(price =>
                        enteredAmount <= price.maxAmount) || selectedPairPriceList[selectedPairPriceList.length - 1]
                ).unitPrice;

                actions.form.change(
                    BUY_CRYPTOCURRENCY_FORM,
                    'getAmount',
                    giveAmount ? (enteredAmount * unitPrice) : 0,
                );
                actions.form.touch(BUY_CRYPTOCURRENCY_FORM, 'getAmount');
            }

            if (action === OfferAction.GET) {
                const enteredAmount = parseAmount(getAmount);
                const unitPrice = (selectedPairPriceList.find(
                        price => enteredAmount <= price.volume) || selectedPairPriceList[selectedPairPriceList.length - 1]
                ).unitPrice;

                actions.form.change(
                    BUY_CRYPTOCURRENCY_FORM,
                    'giveAmount',
                    getAmount ? (enteredAmount / unitPrice) : 0,
                );
                actions.form.touch(BUY_CRYPTOCURRENCY_FORM, 'giveAmount');
            }
        } else {
            actions.form.change(
                BUY_CRYPTOCURRENCY_FORM,
                'giveAmount', undefined,
            );
            actions.form.touch(BUY_CRYPTOCURRENCY_FORM, 'giveAmount');
            actions.form.change(
                BUY_CRYPTOCURRENCY_FORM,
                'getAmount',undefined,
            );
            actions.form.change(
                BUY_CRYPTOCURRENCY_FORM,
                'pair', this.props.pairs.priceOrder[0],
            );
            actions.form.touch(BUY_CRYPTOCURRENCY_FORM, 'pair');
        }
    }

    handleAmountChange(event: any, value: any, previousValue: any, fieldName?: string) {
        const {
            actions,
        } = this.props;

        actions.form.change(
            BUY_CRYPTOCURRENCY_FORM,
            'action',
            fieldName === 'giveAmount' ? OfferAction.GIVE : OfferAction.GET,
        );
    }

    renderFields() {
        const {
            formValues,
            t,
        } = this.props;

        if (!formValues || !formValues.pair) {
            return null;
        }

        const selectedPair = parsePair(formValues.pair);

        return (
            <React.Fragment>
                <Separator/>
                <Row>
                    <Col lg={5}>
                        <Field
                            name="giveAmount"
                            label={t('teaser.amount_want_spend')}
                            suffix={<CurrencyIcon crypo={selectedPair.giveCurrency}/>}
                            component={NumberField}
                            validate={[
                                validations.required,
                                giveAmountValidation,
                            ]}
                            onChange={this.handleAmountChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <div className={styles['arrows']}>
                            <ArrowsIcon/>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Field
                            name="getAmount"
                            label={t('teaser.amount_will_get')}
                            suffix={<CurrencyIcon crypo={selectedPair.getCurrency}/>}
                            component={NumberField}
                            decimalScale={5}
                            validate={[
                                validations.required,
                            ]}
                            onChange={this.handleAmountChange}
                        />
                    </Col>
                </Row>

                <div className={styles['actions-row']}>
                    <Row>
                        <Col lg={7}>
                            <div className={styles['amount-explanation']}>
                                <Typography type="text">
                                    {t('teaser.amount_is_approximate')}
                                </Typography>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <Button text={t('teaser.buy')} block/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            pairs: {
                prices,
                priceOrder
            },
            showUserSteps,
            historyPairs,
            handleSubmit,
            t,
        } = this.props;

        const renderCryptocurrencyOption = (renderProps: RenderOptionProps) => {
            const pairPrice = prices[renderProps.value];
            const pairHistoryPrices = historyPairs && historyPairs.content && historyPairs.content[`EUR/${renderProps.value.split('/')[1]}`] || undefined;
            return (
                <CryptocurrencySelectOption
                    {...renderProps}
                    pairHistoryPrices={pairHistoryPrices}
                    pair={pairPrice}
                />
            );
        };

        return (
            <div className={styles['buy-cryptocurrency-form']}>
                {showUserSteps && <div>
                <UserSteps step={StepType.CRYPTO}/>

                <Row>
                    <Col lg={4}>
                        <div className={styles['accepted-payments-text']}>
                            <Typography type="text">{t('teaser.accepted_payments')}</Typography>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className={styles['accepted-payments']}>
                            <VisaIcon/>
                            <MastercardIcon/>
                            {!isDomainUs() && <SepaIcon/>}
                        </div>
                    </Col>
                </Row>
                </div>}

                <Row>
                    <Col lg={9} sm={8} xs={8}>
                        {showUserSteps && <div className={styles['title']}>
                            <Typography type="title">{t('teaser.choose_cryptocurrency')}</Typography>
                        </div>
                        }
                    </Col>
                    <Col lg={3} sm={4} xs={4}>
                        <Row>
                            <Col lg={3} sm={6} xs={2}>
                                <div className={styles['separate']}/>
                            </Col>
                            <Col lg={9} sm={6} xs={10}>
                                <div className={styles['drop-down']}>
                                    <DropDownField
                                        values={[ Currency.EUR, Currency.USD, Currency.AED ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>


                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={12}>
                            <Field
                                name="pair"
                                component={SelectOptions}
                            >
                                {priceOrder.map(pair =>
                                    <SelectOption
                                        disabled={prices[pair].isInactive}
                                        value={pair}
                                        key={pair}
                                        animate
                                        renderOption={renderCryptocurrencyOption}
                                    />
                                )}
                            </Field>
                        </Col>
                    </Row>

                    {this.renderFields()}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state: any): StateProps => ({
    formValues: getFormValues(BUY_CRYPTOCURRENCY_FORM)(state) as BuyCryptocurrencyFormData,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        form: {
            change: bindActionCreators(change, dispatch),
            touch: bindActionCreators(touch, dispatch),
        },
    },
});

// TODO try to use compose here
export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm<BuyCryptocurrencyFormData, Props>({ form: BUY_CRYPTOCURRENCY_FORM })(
            BuyCryptocurrencyForm,
        ),
    ),
);
