import { CoinConfig, CoinConfigurations, ErrorResponse } from "admin/types";
import { notificationsActions } from "common/lib/notifications";
import { Currency } from 'common/types';
import * as H from "history";
import { UseFormReturn } from "react-hook-form";
import { Dispatch } from "redux";

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const className = 'disable-scrolling';

export const toggleScrolling = (toogle: boolean) => {
    if (document && document.body) {
        if (toogle) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    }
};

export const enableScrolling = () => {
    toggleScrolling(false);
};

export const debounce = (callback: any, time = 500) => {
    let interval: number;

    // @ts-ignore
    return (...args: any) => {
        interval && clearTimeout(interval);
        interval = window.setTimeout(() => {
            interval = null;
            callback(...args);
        }, time);
    };
};

const identifiedCurrencies = [Currency.EOS, Currency.XMR, Currency.XRP, Currency.BNB, Currency.XLM, Currency.ATOM, Currency.WAXP];

export const additionalIdentifier = (currency: string) => {
    if (identifiedCurrencies.includes(currency as Currency)) {
        return `offer.${currency}_identifier`;
    }

    return undefined;
};

export const isMainPage = () => {
    return localStorage.getItem('partner') === null;
};

export const isDomainUs = (): boolean => {
    return false;//window.location.toString().indexOf('nevadaex.com') === -1;
}

export const getDomain = (): string => {
    return 'eu'//window.location.toString().indexOf('nevadaex.com') !== -1 ? 'eu' : 'us'
}

export const isIoDomain = () => {
    return false;
    // return getDomain() !== 'eu';
};

export const getPartnerName = () => {
    return localStorage.getItem('partner');
};

export const handleFormErrors = (form: UseFormReturn<any>, dispatch: Dispatch, formFieldsMap: Map<string, string>, response: ErrorResponse) => {
    if (response.code === 'COIN_CONFIG_ALREADY_EXISTS') {
        dispatch(notificationsActions.showNotificationError('Coin already exists'));
    } else {
        const errors = new Array<string>();
        let violations = new Map(Object.entries(response.violations));
        violations.forEach((value, key) => {
            let name = formFieldsMap.get(key);
            if (!name) {
                errors.push(Array.from(value).join(', '))
            } else {
                form.setError(formFieldsMap.get(key), { message: Array.from(value).join(', ') })
            }
        })
    
        if (errors.length > 0) {
            dispatch(notificationsActions.showNotificationError('Close modal and check all form tabs'))
        }
    }
}

export const handleConfigFormErrors = (form: UseFormReturn<any>, formFieldsMap: Map<string, string>, response: ErrorResponse) => {

    if (!response && !response?.violations) {
        return;
    }

    const errors = new Array<string>();
    let violations = new Map(Object.entries(response.violations));

    violations.forEach((value, key) => {
        let name = formFieldsMap.get(key);
        if (!name) {
            errors.push(Array.from(value).join(', '))
        } else {
            console.log('setting error', key, formFieldsMap.get(key), 'msg', Array.from(value).join(', '))
            form.setError(formFieldsMap.get(key), { message: Array.from(value).join(', ') })
        }
    })
}

export function parseExpandedCoinsUrl(location: H.Location) {
    let url = '';
    let expandedCoins = localStorage.getItem('expandedCoins');
    if (expandedCoins != '[]' || expandedCoins != null) {
        if (location.search !== '') {
            const url2 = removeExpand(location.search);
            return url2 + (url2.indexOf('?') !== -1 ? '&' : '?') +  (expandedCoins === null ? '' : `expand=${expandedCoins}`);
        } else {
            return expandedCoins === null ? '/dashboard/coin-config' : `?expand=${expandedCoins}`;
        }
    } else {

        url = removeExpand(location.search);
        return url == '' ? '/dashboard/coin-config' : url;
    }
}

export const refreshExpandedCoinsUrl = (history: H.History, location: H.Location) => {
    let url = parseExpandedCoinsUrl(location);
    history.push(url);
}

export function removeExpand(str: string) {
    let start = str.indexOf("&expand");
    if (start == -1) {
        start = str.indexOf("?expand");
    }
    if (start == -1) {
        return str;
    }
    let end = str.indexOf("]", start);
    if (end == -1) {
        return str;
    }
    return str.substring(0, start) + str.substring(end + 1);
}


export const shouldValidate = () => {
    let skipValidation = localStorage.getItem('skipValidation');
    return !skipValidation ? true : skipValidation === 'false';
}

export const validationRules = (rules: object) => {
    let skipValidation = localStorage.getItem('skipValidation');
    const validate = !skipValidation ? true : skipValidation === 'false';
    if (validate) {
        return rules;
    } else {
        return undefined;
    }
}

export function prepareJsonForCopy(item: CoinConfig): string {
    const itemCopy = JSON.parse(JSON.stringify(item));
    delete itemCopy.isComplete;
    delete itemCopy.lastChangedAt;
    delete itemCopy.lastChangedBy;
    delete itemCopy.lastChangeReason;
    for (const ticker in itemCopy.content.configurations) {
        const coinConfiguration = (itemCopy.content.configurations[ticker] as CoinConfigurations);
        delete coinConfiguration.isMatched;
        delete coinConfiguration.isComplete;
        delete coinConfiguration.executionAggregates;
        delete coinConfiguration.blockchainFees.isComplete;
        delete coinConfiguration.blockchainFees.estimateOrFallback.isComplete;
        delete coinConfiguration.withdrawal.isComplete;
        delete coinConfiguration.trade.isComplete;
        delete coinConfiguration.quote.isComplete;
    }

    return JSON.stringify(itemCopy);
}


export function isFeatureOn(feature?: string, features?: Map<string, boolean>){
    let isOn = true;

    if(!features || !feature) {
        return isOn;
    }

    try{ 
        isOn = features.get(feature);
    } catch (e) {
        const feats = new Map(Object.entries(features));
        isOn = feats.get(feature);
    }

    return isOn;
}