import * as React from 'react';

import { Currency } from 'common/types';
import BtcIcon from 'common/lib/icons/btc.svg';
import EthIcon from 'common/lib/icons/eth.svg';
import DebIcon from 'common/lib/icons/deb.svg';
import EurIcon from 'common/lib/icons/eur.svg';
import UsdIcon from 'common/lib/icons/usd.svg';
import LendIcon from 'common/lib/icons/lend.svg';
import LoopringIcon from 'common/lib/icons/loopring.svg';
import NanoIcon from 'common/lib/icons/nano.svg';
import VergeIcon from 'common/lib/icons/verge.svg';
import BchIcon from 'common/lib/icons/bch.svg';
import IcxIcon from 'common/lib/icons/icx.svg';
import XmrIcon from 'common/lib/icons/xmr.svg';
import XzcIcon from 'common/lib/icons/xzc.svg';
import DaiIcon from 'common/lib/icons/dai.svg';
import BntIcon from 'common/lib/icons/bnt.svg';
import EosIcon from 'common/lib/icons/eos.svg';
import LtcIcon from 'common/lib/icons/ltc.svg';
import XrpIcon from 'common/lib/icons/xrp.svg';
import RvnIcon from 'common/lib/icons/rvn.svg';
import BnbIcon from 'common/lib/icons/bnb.svg';
import SlvdIcon from 'common/lib/icons/slvd.svg';
import BeamIcon from 'common/lib/icons/Beam.svg';
import AtomIcon from 'common/lib/icons/atom-logo.svg';
import EtcIcon from 'common/lib/icons/etc-logo.svg';
import TronIcon from 'common/lib/icons/trx-logo.svg';
import XlmIcon from 'common/lib/icons/xlm-logo.svg';
import ZrxIcon from 'common/lib/icons/zrx-logo.svg';
import DashIcon from 'common/lib/icons/dash.svg';
import AedIcon from 'common/lib/icons/aed.svg';
import WaxpIcon from 'common/lib/icons/waxp.svg';

const CurrencyIcons = {
    [Currency.EUR]: EurIcon,
    [Currency.BTC]: BtcIcon,
    [Currency.ETH]: EthIcon,
    [Currency.DEB]: DebIcon,
    [Currency.LEND]: LendIcon,
    [Currency.LRC]: LoopringIcon,
    [Currency.NANO]: NanoIcon,
    [Currency.XNO]: NanoIcon,
    [Currency.XVG]: VergeIcon,
    [Currency.BCH]: BchIcon,
    [Currency.ICX]: IcxIcon,
    [Currency.XMR]: XmrIcon,
    [Currency.XZC]: XzcIcon,
    [Currency.DAI]: DaiIcon,
    [Currency.BNT]: BntIcon,
    [Currency.EOS]: EosIcon,
    [Currency.LTC]: LtcIcon,
    [Currency.XRP]: XrpIcon,
    [Currency.RVN]: RvnIcon,
    [Currency.BNB]: BnbIcon,
    [Currency.SLVD]: SlvdIcon,
    [Currency.BEAM]: BeamIcon,
    [Currency.USD]: UsdIcon,
    [Currency.ATOM]: AtomIcon,
    [Currency.ETC]: EtcIcon,
    [Currency.TRX]: TronIcon,
    [Currency.XLM]: XlmIcon,
    [Currency.ZRX]: ZrxIcon,
    [Currency.DASH]: DashIcon,
    [Currency.AED]: AedIcon,
    [Currency.WAXP]: WaxpIcon,
};

interface Props {
    crypo: Currency,
}

export default (props: Props) => {
    const CurrencyIcon = CurrencyIcons[props.crypo];

    if (CurrencyIcon) {
        return <CurrencyIcon/>;
    }

    return null;
};
