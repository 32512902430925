import * as React from 'react';
import {NavLink} from 'react-router-dom';
import classnames from 'classnames';
import PoweredByElastum from 'common/lib/icons/powered-by-elastum.svg';
import Bancor from 'common/lib/icons/logo-elastum-blue.svg';
import Payoline from 'common/lib/icons/payoline-logo.svg';
import Tizer from 'common/lib/icons/tizer-logo.svg';
import Proccx from 'common/lib/icons/proccx.svg';
import CryptoTraders from 'common/lib/icons/cryptotraders-logo.svg';
import YalaCoins from 'common/lib/icons/logo-yalaCoins.svg';
import CryptoLocally from 'common/lib/icons/cryptolocally-logo2.svg';
import ChangeNowLogo from 'common/lib/icons/change-now-logo.svg';
import {getPartnerName, isMainPage} from 'common/lib/utils';

import * as styles from './partner-header.scss';
import UserProfile from '../../user';

const PartnerHeader = () => {

    const map = new Map();

    map.set('bancor', <Bancor/>);
    map.set('payoline', <Payoline/>);
    map.set('cryptotraders', <CryptoTraders/>);
    map.set('tizer', <Tizer/>);
    map.set('proccx', <Proccx/>);
    map.set('yalacoins', <YalaCoins/>);
    map.set('cryptolocally', <CryptoLocally/>);
    map.set('changenow', <ChangeNowLogo/>);

    const Logo = map.get(getPartnerName());
    const leftCss = classnames(
        styles['left'],
        !isMainPage() && styles[`left-${getPartnerName()}`],
    );

    const rightCss = classnames(
        styles['right'],
        !isMainPage() && styles[`right-${getPartnerName()}`],
    );
    return (
        <div>
            <div className={styles['partner-header']}>
                <div className={leftCss}>
                    {Logo}
                </div>
                <div className={rightCss}>
                    <NavLink to={`/partners/${getPartnerName()}`}>
                        <PoweredByElastum/>
                    </NavLink>
                </div>
            </div>
            <div className={styles['user-profile']}>
                <UserProfile/>
            </div>
        </div>
    );
};

export default PartnerHeader;
