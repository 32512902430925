exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.validation-error__2mGIF {\n  font-family: Ubuntu, serif;\n  font-size: 14px;\n  font-weight: normal;\n  color: #F75959; }\n  .validation-error__2mGIF .message__3Zz9N.message-enter__3YS-R {\n    opacity: 0.01;\n    transform: translateY(-100%); }\n  .validation-error__2mGIF .message__3Zz9N.message-enter-active__3hfbO {\n    opacity: 1;\n    transform: translateY(0%);\n    transition: all 300ms ease-out; }\n", "", {"version":3,"sources":["/usr/app/src/common/lib/fields/partials/validation-error.scss"],"names":[],"mappings":"AAaA;;;;;;EAME;AACF;;;;;;EAME;AACF;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,oBAAoB;EACpB,eAAe,EAAE;EACjB;IACE,cAAc;IACd,6BAA6B,EAAE;EACjC;IACE,WAAW;IACX,0BAA0B;IAC1B,+BAA+B,EAAE","file":"validation-error.scss","sourcesContent":[":export {\n  background: #f3f4fa;\n  white: #FFFFFF;\n  gray10: #AAACC6;\n  gray20: #E0E2EC;\n  gray30: #F0F1F8;\n  red: #F75959;\n  blue-gray: #717391;\n  primary-color-cryptotraders: #241c4a;\n  secondary-color: #FECF2E;\n  primary-color: #2E304E;\n  secondary-color-changenow: #5C5780; }\n\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.validation-error {\n  font-family: Ubuntu, serif;\n  font-size: 14px;\n  font-weight: normal;\n  color: #F75959; }\n  .validation-error .message.message-enter {\n    opacity: 0.01;\n    transform: translateY(-100%); }\n  .validation-error .message.message-enter-active {\n    opacity: 1;\n    transform: translateY(0%);\n    transition: all 300ms ease-out; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780",
	"validation-error": "validation-error__2mGIF",
	"message": "message__3Zz9N",
	"message-enter": "message-enter__3YS-R",
	"message-enter-active": "message-enter-active__3hfbO"
};