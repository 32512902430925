import * as React from 'react';
import classnames from 'classnames';

import * as styles from './separator.scss';

interface Props {
    height?: 'small'
}

const Separator = (props: Props) => {
    const { height } = props;

    const classSeparator = classnames(
        styles['separator'],
        height && styles[height],
    );

    return <div className={classSeparator}/>;
};

export default Separator;
