import { actions as authActions } from 'client/components/user';
import LogoElastumIcon from 'common/lib/icons/logo-elastum.svg';

import { isDomainUs, isIoDomain, toggleScrolling } from 'common/lib/utils';
import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';

import * as styles from './header.scss';
import Menu from './menu';
import MenuIcon from './menu.svg';

interface DispatchProps {
    actions: {
        auth: typeof authActions,
    }
}

interface HeaderProps {
    showBuyCryptoButton?: boolean,
}

type Props = DispatchProps & WithNamespaces & HeaderProps;

interface State {
    isOpened: boolean,
}

class Header extends React.Component<Props, State> {
    public readonly state: State = {
        isOpened: false,
    };

    constructor(props: Props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleScrolling = this.toggleScrolling.bind(this);
    }

    componentDidMount() {
        const {
            actions,
        } = this.props;

        actions.auth.fetchUser();
    }

    toggleMenu() {
        this.setState(state => {
            return {
                isOpened: !state.isOpened,
            };
        }, this.toggleScrolling);
    }

    toggleScrolling() {
        const {
            isOpened,
        } = this.state;

        toggleScrolling(isOpened);
    }

    render() {
        const {
            t,
            showBuyCryptoButton,
        } = this.props;

        const {
            isOpened,
        } = this.state;

        return (
            <div className={styles['header']}>
                {isIoDomain() && <NavLink to="/">
                    <LogoElastumIcon/>
                </NavLink>}
                {!isDomainUs() && <a className={styles['sell-crypto-button-small']} href="https://simplex.com/account/sell" target='_blank'>
                    {t('menu.sell_crypto')}
                </a>}
                <MenuIcon className={styles['menu-icon']} onClick={this.toggleMenu}/>
                <Menu
                    isOpen={isOpened}
                    showBuyCryptoButton={showBuyCryptoButton}
                    closeMenu={this.toggleMenu}
                />

                <div className={styles['navigation']}>
                    {!isDomainUs() && <NavLink
                        to="/partnerships"
                        activeClassName={styles['active']}
                        className={styles['item']}
                    >
                        {t('menu.list_token')}
                    </NavLink>
                    }
                    {isIoDomain() &&
                        <a href="https://elastum.zendesk.com" target="_blank" rel="noopener" className={styles['item']}>
                        {t('menu.faq')}
                    </a>}
                    {isIoDomain() && <a href="https://medium.com/@elastum" target="_blank" rel="noopener" className={styles['item']}>
                        {t('menu.blog')}
                    </a>}
                    <NavLink
                        to="/legal"
                        activeClassName={styles['active']}
                        className={styles['item']}
                    >
                        {t('menu.legal')}
                    </NavLink>

                    {showBuyCryptoButton &&
                        <NavLink className={styles['buy-crypto-button']} to="/">
                            {t('menu.buy_crypto')}
                        </NavLink>
                    }
                </div>
                {!isDomainUs() && <a className={styles['sell-crypto-button-big']} href="https://simplex.com/account/sell" target='_blank'>
                    {t('menu.sell_crypto')}
                </a>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default withNamespaces()(connect(null, mapDispatchToProps)(Header));
