import { Dispatch } from 'redux';
import { routerActions } from 'connected-react-router';

import * as api from 'client/api';
import { FETCH_USER, FETCH_USER_SUCCESS, LOGOUT_USER } from './constants';
import {getPartnerName, isMainPage} from "../../../common/lib/utils";

const logout = () => (dispatch: Dispatch) => {
    api.logout();
    dispatch({type: LOGOUT_USER});
    dispatch(routerActions.push(isMainPage() ? '/' : `/partners/${getPartnerName()}/`));
};

const clearUser = () => (dispatch: Dispatch) => {
    dispatch({type: LOGOUT_USER});
};

const fetchUser = () => async (dispatch: Dispatch) => {
    dispatch({type: FETCH_USER});

    try {
        const { data } = await api.getUser();

        dispatch({
            type: FETCH_USER_SUCCESS,
            payload: data,
        });
    } catch (e) {
        clearUser()(dispatch);
    }
};

export default {
    fetchUser,
    logout,
    clearUser,
};
