import * as React from 'react';
import classnames from 'classnames';

import TickIcon from 'common/lib/icons/tick.svg';

import { isMainPage, getPartnerName } from 'common/lib/utils';
import * as styles from './select-option.scss';

export interface RenderOptionProps {
    value: string,
    isSelected: boolean,
    disabled: boolean,
    fiat?: string
}

export interface Props {
    value: string,
    disabled?: boolean,
    animate?: boolean,
    isSelected?: boolean,
    onSelectOption?: (value: string) => void,
    label?: string,
    fiat?: string,
    renderOption?: (props: RenderOptionProps) => React.ReactChild,
}

export default (props: Props) => {
    const {
        value,
        label,
        disabled,
        animate,
        isSelected,
        onSelectOption,
        renderOption,
        fiat
    } = props;

    const className = classnames(
        styles['select-option'],
        !isMainPage() && styles[`select-option-${getPartnerName()}`],
        isSelected && styles['is-selected'],
        disabled && styles['disabled'],
        animate && styles['animate'],
    );

    const selectOption = () => {
        if (!disabled && onSelectOption) {
            onSelectOption(value);
        }
    };

    const renderContent = () => {
        if (renderOption) {
            return renderOption({ value, isSelected, disabled, fiat });
        }

        return label;
    };

    return (
        <div className={className} onClick={selectOption}>
            {renderContent()}

            <TickIcon className={styles['tick']}/>
        </div>
    );
};
