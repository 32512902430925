import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-flexbox-grid';
import {withNamespaces, WithNamespaces} from 'react-i18next';
import {Field, Form, getFormValues, InjectedFormProps, reduxForm} from 'redux-form';
import Button from 'common/lib/button';
import Typography from 'common/lib/typography';
import {TextField} from 'common/lib/fields';
import {removeWhitespaces} from 'common/lib/formatter';

import * as styles from './six-digits-form.scss';
import ReCAPTCHA from 'react-google-recaptcha';

export interface ShareSixDigitsFormData {
    digits: string,
}

interface StateProps {
    formValues: ShareSixDigitsFormData,
}

interface OwnProps {
    onClickBack: () => void,
    onFormSubmit: (values: ShareSixDigitsFormData) => void,
    onResendCode: (recaptcha: string) => Promise<any>,
}

// @ts-ignore
const recaptchaSiteKey = RECAPTCHA_SITE_KEY;

interface State {
    url: string,
    recaptchaLoaded: boolean,
    codeSent: boolean,
}

type Props = WithNamespaces & StateProps & OwnProps;

const FORM_NAME = 'SIX_DIGITS_FORM';

class SixDigitsForm extends React.Component<Props & InjectedFormProps<ShareSixDigitsFormData, Props>, State> {

    public recaptcha: React.RefObject<ReCAPTCHA> = React.createRef();
    public readonly state: State = {
        url: undefined,
        codeSent: false,
        recaptchaLoaded: false,
    };
    constructor(props: Readonly<Props & InjectedFormProps<ShareSixDigitsFormData, Props>>) {
        super(props);
        this.onRecaptchaTokenChanged = this.onRecaptchaTokenChanged.bind(this);
        this.onRecaptchaLoaded = this.onRecaptchaLoaded.bind(this);
        this.resendCode = this.resendCode.bind(this);
    }

    resendCode() {
        this.recaptcha.current.execute();
        this.setState({...this.state, codeSent: true})
    }

    onRecaptchaTokenChanged(recaptchaToken: string) {
        return this.props.onResendCode(recaptchaToken);
    }

    onRecaptchaLoaded() {
        this.setState({
            recaptchaLoaded: true,
        });
    }

    render() {
        const {
            t,
            handleSubmit,
            onFormSubmit,
        } = this.props;

        return (
            <div className={styles['login-code-form']}>
                <div className={styles['form-body']}>
                    <Typography type="title" color="primary">
                        {t('six_digits_form.form_title')}
                    </Typography>
                    <div className={styles['form-body-text']}>
                        <Typography type="text" color="primary">
                            {t('six_digits_form.form_content_text')}
                        </Typography>
                    </div>
                    <Typography type="text" color="primary">
                        {t('six_digits_form.form_title')}
                    </Typography>
                    <Form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className={styles['form-inputs']}>
                            <div className={styles['input-container']}>
                                <Field
                                    name="digits"
                                    component={TextField}
                                    mask="9   9   9   9   9   9"
                                    maskChar="&#9679;"
                                    normalize={removeWhitespaces}
                                />
                            </div>
                        </div>
                        <div className={styles['actions-row']}>
                            <Row className={styles['buttons']}>
                                <Col xs={6} sm={6} lg={6}>
                                    <div className={styles['back-arrow-button']}>
                                        <Button preventDefault block text={t('share_email.resend')} onClick={this.resendCode}/>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={6}>
                                    <Button
                                        text={t('six_digits_form.button_login')}
                                        block
                                    />
                                </Col>
                            </Row>
                            {this.state.codeSent && <div className={styles['code-sent']}>
                                <Typography type="text">{t('six_digits_form.code_send')}</Typography>
                            </div>
                            }
                        </div>
                    </Form>

                    <ReCAPTCHA
                        ref={this.recaptcha}
                        sitekey={recaptchaSiteKey}
                        size="invisible"
                        theme="light"
                        onChange={this.onRecaptchaTokenChanged}
                        asyncScriptOnLoad={this.onRecaptchaLoaded}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): StateProps => ({
    formValues: getFormValues(FORM_NAME)(state) as ShareSixDigitsFormData,
});

export default withNamespaces()(
    connect(mapStateToProps)(
        reduxForm<ShareSixDigitsFormData, Props>({form: FORM_NAME})(
            SixDigitsForm,
        ),
    ),
);
