import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import * as styles from './notification-container.scss';

const NotificationContainer: React.FunctionComponent = () => {
    return (
        <div className={styles['notification-container']}>
            <ToastContainer/>
        </div>
    );
};

export default NotificationContainer;
