exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".error-boundary__2oYhQ {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center; }\n", "", {"version":3,"sources":["/usr/app/src/client/components/error-boundary/components/error-boundary.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,wBAAwB;EACxB,oBAAoB,EAAE","file":"error-boundary.scss","sourcesContent":[".error-boundary {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"error-boundary": "error-boundary__2oYhQ"
};