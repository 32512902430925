exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.teaser-page__2k5HI .title__xL6oj,\n.teaser-page__2k5HI .subtitle__-7h0w {\n  font-family: Ubuntu, serif;\n  font-weight: bold;\n  color: white; }\n\n.teaser-page__2k5HI .accepted-payments__3_xzm {\n  margin-top: -61px;\n  margin-bottom: 70px; }\n\n.teaser-page__2k5HI .title__xL6oj {\n  margin-bottom: 16px;\n  font-size: 40px; }\n\n.teaser-page__2k5HI .subtitle__-7h0w {\n  font-size: 32px; }\n", "", {"version":3,"sources":["/usr/app/src/client/pages/teaser/components/teaser-page.scss"],"names":[],"mappings":"AAaA;;;;;;EAME;AACF;;;;;;EAME;AACF;;EAEE,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa,EAAE;;AAEjB;EACE,kBAAkB;EAClB,oBAAoB,EAAE;;AAExB;EACE,oBAAoB;EACpB,gBAAgB,EAAE;;AAEpB;EACE,gBAAgB,EAAE","file":"teaser-page.scss","sourcesContent":[":export {\n  background: #f3f4fa;\n  white: #FFFFFF;\n  gray10: #AAACC6;\n  gray20: #E0E2EC;\n  gray30: #F0F1F8;\n  red: #F75959;\n  blue-gray: #717391;\n  primary-color-cryptotraders: #241c4a;\n  secondary-color: #FECF2E;\n  primary-color: #2E304E;\n  secondary-color-changenow: #5C5780; }\n\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.teaser-page .title,\n.teaser-page .subtitle {\n  font-family: Ubuntu, serif;\n  font-weight: bold;\n  color: white; }\n\n.teaser-page .accepted-payments {\n  margin-top: -61px;\n  margin-bottom: 70px; }\n\n.teaser-page .title {\n  margin-bottom: 16px;\n  font-size: 40px; }\n\n.teaser-page .subtitle {\n  font-size: 32px; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780",
	"teaser-page": "teaser-page__2k5HI",
	"title": "title__xL6oj",
	"subtitle": "subtitle__-7h0w",
	"accepted-payments": "accepted-payments__3_xzm"
};