import { GroupBase, StylesConfig } from 'react-select';

import * as colors from 'common/styles/colors.scss';

interface Props {
    hasError: boolean;
    isClearable: boolean;
    fixedInputHeight?: boolean;
}

export const getCustomSelectStyle = <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>({
    hasError,
    isClearable,
    fixedInputHeight = false,
}: Props): StylesConfig<OptionType, IsMulti, Group> => (
    {
        container: base => (
            {
                ...base,
                fontSize: '14px',
                fontFamily: 'Ubuntu, serif',
            }
        ),
        input: base => (
            {
                ...base,
                color: !isClearable && 'transparent',
                input: {
                    fontSize: '14px',
                    fontFamily: 'Ubuntu, serif',
                },
            }
        ),
        control: (base, state) => (
            {
                ...base,
                border: `1px solid ${hasError ? colors['red'] : colors['gray10']}`,
                backgroundColor: colors['gray30'],
                borderRadius: '5px',
                height: fixedInputHeight ? `56px` : 'auto',
                boxShadow: 'none',
                minHeight: '56px',
                overflowX: 'hidden',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    width: '4px',
                    height: '0px',
                },
                '::-webkit-scrollbar-track': {
                    background: colors['gray20'],
                },
                '::-webkit-scrollbar-thumb': {
                    background: colors['gray10'],
                },
                '::-webkit-scrollbar-thumb:hover': {
                    background: colors['blue-gray'],
                },
                ...(
                    state.isDisabled && {
                        pointerEvents: 'auto',
                        cursor: 'not-allowed',
                    }
                ),
                '&:hover': {
                    boxShadow: 'none',
                },
            }
        ),
        valueContainer: base => (
            {
                ...base,
                paddingLeft: '16px',
            }
        ),
        indicatorsContainer: base => (
            {
                ...base,
                alignItems: fixedInputHeight ? 'flex-start' : 'center',
            }
        ),
        indicatorSeparator: (base, state) => (
            {
                ...base,
                height: fixedInputHeight ? '36px' : 'auto',
                display: (
                    isClearable && state.hasValue
                ) ? 'inline-block' : 'none',
            }
        ),
        multiValue: base => (
            {
                ...base,
                backgroundColor: colors['secondary-color'],
                borderRadius: '5px',
            }
        ),
        multiValueLabel: base => (
            {
                ...base,
                fontSize: '14px',
                fontFamily: 'Ubuntu, serif',
            }
        ),
        multiValueRemove: base => (
            {
                ...base,
                ':hover': {
                    backgroundColor: colors['red'],
                },
            }
        ),
        menu: base => (
            {
                ...base,
                boxShadow: 'none',
                border: `1px solid ${colors['gray10']}`,
                marginTop: '1px',
                zIndex: 1000,
            }
        ),
        menuList: base => (
            {
                ...base,
                background: colors['background'],
                '::-webkit-scrollbar': {
                    width: '4px',
                    height: '0px',
                },
                '::-webkit-scrollbar-track': {
                    background: colors['gray20'],
                },
                '::-webkit-scrollbar-thumb': {
                    background: colors['gray10'],
                },
                '::-webkit-scrollbar-thumb:hover': {
                    background: colors['blue-gray'],
                },
            }
        ),
        option: (base, state) => (
            {
                ...base,
                ':hover': {
                    backgroundColor: colors['primary-color-cryptotraders'],
                    color: colors['secondary-color'],
                },
                backgroundColor: state.isSelected ? colors['secondary-color-changenow'] : 'transparent',
                padding: '16px 17px',
                cursor: 'pointer',
                color: state.isSelected ? colors['secondary-color'] : colors['primary-color'],
            }
        ),
        clearIndicator: base => (
            {
                ...base,
                paddingTop: fixedInputHeight ? '18px' : '10px',
                cursor: 'pointer',
            }
        ),
        dropdownIndicator: base => (
            {
                ...base,
                paddingTop: fixedInputHeight ? '18px' : '10px',
                cursor: 'pointer',
            }
        ),
    }
);
