import * as React from 'react';
import classnames from 'classnames';
import { PartnerHeader } from 'client/components/header';

import { getPartnerName } from 'common/lib/utils';
import * as styles from './partner-page.scss';
import Typography from 'common/lib/typography';
import Footer from '../footer/footer';

interface Props {
    children: React.ReactChild,
    userWallet?: string,
    userMemo?: string,
    validate?: boolean,

}

export default (props: Props) => {
    const {
        children,
        userMemo,
        userWallet,
    } = props;

    const classNames = classnames(
        styles['partner-page'],
        styles[`${getPartnerName()}-page`],
    );

    const partners = [ 'bancor', 'valatech', 'cryptotraders', 'tizer', 'proccx' ,'yalacoins', 'cryptolocally', 'changenow'];

    let isPartnerAvailable = partners.indexOf(getPartnerName()) !== -1;

    if (props.validate) {
        if (getPartnerName() === 'bancor') {
            if (!!!userMemo || !!!userWallet) {
                isPartnerAvailable = false;
            }
        }
    }

    return (
        <div className={classNames}>
            <div className={styles['center']}>
                {'bancor' !== getPartnerName() && <div className={styles['header']}>
                    <PartnerHeader/>
                </div>}

                <div className={styles['content']}>
                    {isPartnerAvailable && children}
                    {!isPartnerAvailable &&
                        <Typography type={"header"}>
                            Page Not Found!
                        </Typography>
                    }
                </div>
                <div className={styles['footer']}>
                    <div className={styles['footer-container']}>
                        <Footer hideSocNetworks/>
                    </div>
                </div>
            </div>
        </div>
    );
};
