import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import * as styles from './radio-button.scss';

interface OwnProps {
    type?: string,
    label?: string,
    options: object,
}

type Props = OwnProps & WrappedFieldProps;

export const RadioButtons: React.FunctionComponent<Props> = (props: Props) => {
    const {input, options, label} = props;

    const renderRadioButtons = (key: string) => {
        const inputKey = input.name + '_' + key;

        return (
            <div className={styles['radio-button']} key={inputKey}>
                <input
                    {...input}
                    id={inputKey}
                    type="radio"
                    value={key}
                    checked={key === input.value}
                />
                <label htmlFor={inputKey}>
                    {options[key as keyof typeof options]}
                </label>
            </div>
        );
    };

    return (
        <div className={styles['radio-buttons']}>
            <div>
                {label}
            </div>
            <div className={styles['radio-buttons-wrapper']}>
                {Object.keys(options).map(renderRadioButtons)}
            </div>
        </div>
    );
};

RadioButtons.defaultProps = {
    options: {},
};

export default RadioButtons;
