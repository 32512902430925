import * as React from 'react';

import ErrorPlaceholder from 'client/components/error-placeholder';

import * as styles from './error-boundary.scss';

interface State {
    hasError: boolean,
}

class ErrorBoundary extends React.Component <State> {
    public readonly state: State = {
        hasError: false,
    };

    componentDidCatch(error: Error) {
        console.log(error);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <div className={styles['error-boundary']}><ErrorPlaceholder /></div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
