exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.user-profile__3dSjl {\n  display: flex;\n  align-items: center; }\n  .user-profile__3dSjl .email__2njlq {\n    margin-right: 16px; }\n  .user-profile__3dSjl .logout__1xubN {\n    cursor: pointer;\n    transition: opacity 0.2s; }\n    .user-profile__3dSjl .logout__1xubN:hover {\n      opacity: 0.8; }\n", "", {"version":3,"sources":["/usr/app/src/client/components/user/components/user-profile.scss"],"names":[],"mappings":"AAaA;;;;;;EAME;AACF;;;;;;EAME;AACF;EACE,cAAc;EACd,oBAAoB,EAAE;EACtB;IACE,mBAAmB,EAAE;EACvB;IACE,gBAAgB;IAChB,yBAAyB,EAAE;IAC3B;MACE,aAAa,EAAE","file":"user-profile.scss","sourcesContent":[":export {\n  background: #f3f4fa;\n  white: #FFFFFF;\n  gray10: #AAACC6;\n  gray20: #E0E2EC;\n  gray30: #F0F1F8;\n  red: #F75959;\n  blue-gray: #717391;\n  primary-color-cryptotraders: #241c4a;\n  secondary-color: #FECF2E;\n  primary-color: #2E304E;\n  secondary-color-changenow: #5C5780; }\n\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.user-profile {\n  display: flex;\n  align-items: center; }\n  .user-profile .email {\n    margin-right: 16px; }\n  .user-profile .logout {\n    cursor: pointer;\n    transition: opacity 0.2s; }\n    .user-profile .logout:hover {\n      opacity: 0.8; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780",
	"user-profile": "user-profile__3dSjl",
	"email": "email__2njlq",
	"logout": "logout__1xubN"
};