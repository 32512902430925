import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { formatError } from './lib/formatter';
import { notificationsActions } from './lib/notifications';

class RestService {
    private readonly axiosInstance: AxiosInstance;
    public readonly baseURL = '/';

    constructor(environment: 'client' | 'admin' = 'client') {
        this.axiosInstance = axios.create({
            baseURL: this.baseURL,
            timeout: 60 * 1000
        });

        this.axiosInstance.interceptors.response.use(response => {
            if (response && response.status !== 200) {
                return Promise.reject(response);
            }

            return response;
        },error => {
            if (environment === 'admin') {
                if (error && error.response && error.response.status === 401) {
                    window.location.href = '/login';
                } else if (
            error &&
            error.response &&
            error.response.status !== 401 &&
            error.response.status !== 200
          ) {
            if (error.request.responseURL) {
              if (!error.request.responseURL.includes('/admin/coin-config/')) {
                notificationsActions.showNotificationError(
                  formatError(error.response)
                );
              }
            }
          }
            }

            return Promise.reject(error.response);
        },
        );
    }

    public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.axiosInstance.get(url, config);
    }

    public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return this.axiosInstance.post(url, data, config);
    }

    public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return this.axiosInstance.put(url, data, config);
    }

    public delete<T = any>(url: string,config?: AxiosRequestConfig): Promise<T> {
        return this.axiosInstance.delete(url, config);
    }
}

export default RestService;
