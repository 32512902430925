import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { History } from 'history';

import createRootReducer from 'client/reducers';

export function configureStore(history: History) {
    const historyMiddleware = routerMiddleware(history);
    const mainMiddleware = composeWithDevTools(applyMiddleware(thunkMiddleware, historyMiddleware));

    return createStore(createRootReducer(history), {}, mainMiddleware);
}
