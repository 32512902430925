import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { getPairPriceHistory, getPairsPrices, getUser, sendMagicLink } from 'client/api';
import ErrorPlaceholder from 'client/components/error-placeholder';
import PageWithIllustration from 'client/components/page-with-illustration';
import { actions as authActions } from 'client/components/user';
import offerActions from 'client/pages/offer/actions';
import { PairsHistoryPricesModel, PairsPricesModel } from 'client/types';
import { parseAmount } from 'common/lib/formatter';
import MagicLinkIllustration from 'common/lib/icons/magic-link.svg';
import SelectCoinIllustration from 'common/lib/icons/select-coin.svg';
import { JumpingDots } from 'common/lib/loaders';
import { scrollToTop } from 'common/lib/utils';

import BuyCryptocurrencyForm, { BuyCryptocurrencyFormData } from './buy-cryptocurrency-form';
import LinkSentPlaceholder from './link-sent-placeholder';
import ShareEmailForm, { ShareEmailFormData } from './share-email-form';

import { State as PageState } from '../reducer';
import { getPage } from '../selectors';
import * as styles from './teaser-page.scss';

enum Steps {
    BUY_FORM,
    SHARE_EMAIL_FORM,
    LINK_SENT_PLACEHOLDER,
}

interface DispatchProps {
    actions: {
        offer: typeof offerActions,
        auth: typeof authActions,
    }
}

interface StateProps {
    page: PageState,
}

type Props = DispatchProps & WithNamespaces & StateProps & RouteComponentProps<any>;

interface State {
    loadingPairs: boolean,
    showError: boolean,
    pairs?: PairsPricesModel,
    historyPairs?: PairsHistoryPricesModel,
    currentStep: Steps,
    buyCryptocurrencyForm?: BuyCryptocurrencyFormData,
    userEmail?: string,
}

class TeaserPage extends React.Component<Props, State> {
    public readonly state: State = {
        loadingPairs: true,
        showError: false,
        currentStep: Steps.BUY_FORM,
    };

    constructor(props: Props) {
        super(props);

        this.showBuyForm = this.showBuyForm.bind(this);
        this.buyCrypto = this.buyCrypto.bind(this);
        this.shareEmail = this.shareEmail.bind(this);
        this.sendMagicLink = this.sendMagicLink.bind(this);
        this.loadPairs = this.loadPairs.bind(this);
        this.loadHistoryPrice = this.loadHistoryPrice.bind(this);
        this.renderBuyForm = this.renderBuyForm.bind(this);
        this.renderCurrentStep = this.renderCurrentStep.bind(this);
        this.getRightContent = this.getRightContent.bind(this);
        this.getTitle = this.getTitle.bind(this);

        localStorage.removeItem('partner');
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.page.fiat !== prevProps.page.fiat) {
            this.loadHistoryPrice();
            this.loadPairs();
        }
    }

    componentDidMount() {
        scrollToTop();

        this.loadHistoryPrice();
        this.loadPairs();
    }

    showBuyForm() {
        this.loadPairs();
        this.setState({
            buyCryptocurrencyForm: null,
            currentStep: Steps.BUY_FORM,
        });
    }

    loadHistoryPrice() {
        getPairPriceHistory()
            .then(response => {
                this.setState({
                    historyPairs: response.data,
                });
            });
    }

    loadPairs() {
        this.setState({
            loadingPairs: true,
            showError: false,
        });
        getPairsPrices(this.props.page.fiat)
            .then(response => {
                const pairs = response.data;

                this.setState({
                    loadingPairs: false,
                    pairs,
                });
            })
            .catch(() => {
                this.setState({
                    loadingPairs: false,
                    showError: true,
                });
            });
    }

    shareEmail(values: ShareEmailFormData, recaptcha: string) {
        return this.sendMagicLink(values.email, recaptcha, this.state.buyCryptocurrencyForm);
    }

    buyCrypto(values: BuyCryptocurrencyFormData) {
        const {
            actions,
        } = this.props;

        return getUser()
            .then(() => {
                return actions.offer.createOffer(values.pair, values.action, values.giveAmount, values.getAmount);
            })
            .catch(() => {
                scrollToTop();
                actions.auth.clearUser();

                this.setState({
                    buyCryptocurrencyForm: values,
                    currentStep: Steps.SHARE_EMAIL_FORM,
                });
            });
    }

    sendMagicLink(email: string, recaptcha: string, buyCryptocurrencyForm: BuyCryptocurrencyFormData) {
        const {
            pair,
            action,
            giveAmount,
            getAmount,
        } = buyCryptocurrencyForm;

        const amount = parseAmount(action === 'GIVE' ? giveAmount : getAmount);

        return sendMagicLink(email, pair, action, amount, recaptcha)
            .then(() => {
                scrollToTop();
                this.setState({
                    userEmail: email,
                    currentStep: Steps.LINK_SENT_PLACEHOLDER,
                });
            })
            .catch(() => {
                scrollToTop();
                this.setState({
                    showError: true,
                });
            });
    }

    renderBuyForm() {
        const {
            loadingPairs,
            pairs,
            historyPairs,
        } = this.state;

        if (loadingPairs) {
            return <JumpingDots dark/>;
        }

        return (
            <BuyCryptocurrencyForm
                pairs={pairs}
                historyPairs={historyPairs}
                initialValues={
                    {
                        pair: pairs.priceOrder[0],
                    }
                }
                showUserSteps
                onSubmit={this.buyCrypto}
            />
        );
    }

    renderCurrentStep() {
        const {
            currentStep,
            userEmail,
            showError,
        } = this.state;

        const {
            t,
        } = this.props;

        if (showError) {
            return <ErrorPlaceholder onTryAgain={this.loadPairs}/>;
        }

        if (currentStep === Steps.BUY_FORM) {
            return this.renderBuyForm();
        }

        if (currentStep === Steps.SHARE_EMAIL_FORM) {
            return (
                <ShareEmailForm
                    onFormSubmit={this.shareEmail}
                    onClickBack={this.showBuyForm}
                    titleText={t('share_email.it_is_magical')}
                    subtitleText={t('share_email.email_with_magic_link')}
                    button={t('share_email.send_magic_link')}
                    initialValues={{
                        email: userEmail,
                    }}
                    magic
                />
            );
        }

        if (currentStep === Steps.LINK_SENT_PLACEHOLDER) {
            return (
                <LinkSentPlaceholder
                    email={userEmail}
                    onClickBack={this.showBuyForm}
                />
            );
        }

        return null;
    }

    getRightContent() {
        const {
            currentStep,
        } = this.state;

        if (currentStep === Steps.BUY_FORM) {
            return <SelectCoinIllustration/>;
        }

        return <MagicLinkIllustration/>;
    }

    getTitle() {
        const {
            t,
            page: { fiat }
        } = this.props;

        const {
            currentStep,
        } = this.state;

        if (currentStep === Steps.BUY_FORM) {
            return (
                <React.Fragment>
                    <div className={styles['title']}>{t(`teaser.reasonable_rates_${fiat.toLowerCase()}`)}</div>
                    <div className={styles['subtitle']}>{t(`teaser.start_from_${fiat.toLowerCase()}`)}</div>
                </React.Fragment>
            );
        }

        return null;
    }

    render() {

        return (
            <div className={styles['teaser-page']}>
                <PageWithIllustration
                    rightChildren={this.getRightContent()}
                    title={this.getTitle()}
                >
                    {this.renderCurrentStep()}
                </PageWithIllustration>
            </div>
        );
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        page: getPage(state)
    })
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        offer: bindActionCreators(offerActions, dispatch),
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(TeaserPage));
