import classnames from 'classnames';
import { actions as authActions, selectors as authSelectors, State as UserState } from 'client/components/user';
import CloseIcon from 'common/lib/icons/close.svg';
import Logo from 'common/lib/icons/logo-elastum.svg';

import { enableScrolling } from 'common/lib/utils';
import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as styles from './menu.scss';

interface OwnProps {
    closeMenu: () => void,
    isOpen: boolean,
    showBuyCryptoButton: boolean,
}

interface StateProps {
    user: UserState,
}

interface DispatchProps {
    actions: {
        auth: typeof authActions,
    }
}

type Props = OwnProps & StateProps & DispatchProps & WithNamespaces;

const Menu = (props: Props) => {
    const {
        t,
        closeMenu,
        actions,
        isOpen,
        showBuyCryptoButton,
        user: {
            loaded,
            user,
        },
    } = props;

    const className = classnames(
        styles['menu'],
        isOpen && styles['menu-open'],
    );

    const close = () => {
        enableScrolling();
        closeMenu();
    };

    return (
        <div className={className}>
            <div className={styles['header']}>
                <NavLink onClick={close} to="/">
                    <Logo className={styles['logo-white']} />
                </NavLink>
                <CloseIcon className={styles['close-logo']} onClick={closeMenu}/>
            </div>

            <div className={styles['link-list']}>
                {showBuyCryptoButton &&
                    <NavLink
                        onClick={close}
                        className={styles['link']}
                        to="/"
                    >
                        {t('menu.buy_crypto')}
                    </NavLink>
                }
                <NavLink
                    onClick={close}
                    to="/partnerships"
                    className={styles['link']}
                >
                    {t('menu.list_token')}
                </NavLink>
                <a
                    href="https://elastum.zendesk.com"
                    target="_blank"
                    rel="noopener"
                    className={styles['link']}
                >
                    {t('menu.faq')}
                </a>
                <a
                    href="https://medium.com/@elastum"
                    target="_blank"
                    rel="noopener"
                    className={styles['link']}
                >
                    {t('menu.blog')}
                </a>
                <NavLink
                    to="/legal"
                    className={styles['link']}
                >
                    {t('menu.legal')}
                </NavLink>
                <span style={{"color": "red"}}>{loaded}</span>
            </div>

            {(!!user && !!user.email) &&
            <div className={styles['logout-bottom']}>
                <button className={styles['logout']} onClick={actions.auth.logout}>{t('menu.logout')}</button>
                <div className={styles['email']}>{user.email}</div>
            </div>
            }

            <div className={styles['icon-container']}/>

        </div>
    );
};

const mapStateToProps = (state: any): StateProps => ({
    user: authSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Menu));
