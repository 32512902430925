import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import { default as FieldWrapper, OwnProps as FieldWrapperProps } from './../field-wrapper/field-wrapper';

import * as styles from './number-field.scss';

export interface OwnProps {
    decimalScale?: number;
}

type Props = OwnProps & FieldWrapperProps & WrappedFieldProps;

const NumberField = (props: Props) => {
    const {
        placeholder,
        input,
        disabled,
        decimalScale = 2,
        meta,
        ...rest
    } = props;

    const hasError: boolean = meta.touched && !!meta.error;
    const className = classnames(
        styles['number-field'],
        hasError && styles['has-error'],
        disabled && styles['disabled'],
        styles['big'],
    );

    return (
        <FieldWrapper input={input} meta={meta} {...rest} disabled={disabled}>
            <NumberFormat
                thousandSeparator
                isNumericString
                allowNegative={false}
                decimalScale={decimalScale}
                fixedDecimalScale
                className={className}
                autoComplete="Off"
                autoCorrect="Off"
                type="tel"
                disabled={disabled}
                placeholder={placeholder}
                {...input}
            />
        </FieldWrapper>
    );
};

export default NumberField;
