import Tippy from '@tippy.js/react';
import * as React from 'react';

import * as styles from './popover.scss';

interface Props {
    children: React.ReactChild,
    text: string,
    clickedText?: string,
    inline?: boolean,
}

interface State {
    clicked: boolean,
}

export default class Popover extends React.PureComponent<Props, State> {
    public readonly state: State = {
        clicked: false,
    };

    constructor(props: Props) {
        super(props);

        this.resetClick = this.resetClick.bind(this);
        this.renderText = this.renderText.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    resetClick() {
        this.setState({
            clicked: false,
        });
    }

    onClick() {
        const {
            clickedText,
        } = this.props;

        if (clickedText) {
            this.setState({
                clicked: true,
            });
        }
    }

    renderText() {
        const {
            text,
            clickedText,
        } = this.props;

        const {
            clicked,
        } = this.state;

        if (clickedText && clicked) {
            return clickedText;
        }

        return text;
    }

    render() {
        const {
            clicked,
        } = this.state;

        const {
            children,
            inline = false,
        } = this.props;

        return (
            <div onClick={this.onClick} style={{display: inline ? 'inline' : 'block'}}>
                <Tippy
                    arrow={true}
                    content={this.renderText()}
                    theme={!clicked ? 'light' : 'dark'}
                    className={styles['tippy-popper']}
                    onHidden={this.resetClick}
                    hideOnClick={false}
                >
                    <div style={{display: inline ? 'inline' : 'block'}}>
                        {children}
                    </div>
                </Tippy>
            </div>
        );
    }
}
