import { Dispatch } from 'redux';
import { routerActions } from 'connected-react-router';

import { notificationsActions } from 'common/lib/notifications';
import { formatError } from 'common/lib/formatter';
import { isMainPage, getPartnerName } from 'common/lib/utils';
import * as api from 'client/api';

const startVerification = (paymentDetailsId: string) => () => {
    api.startVerification(paymentDetailsId)
        .then(response => {
            window.location.href = response.data.kycUrl;
        });
};

const finishVerification = (token: string) => (dispatch: Dispatch) => {
    api.finishVerification(token)
        .then(response => {
            const baseUrl = isMainPage() ? '/' : `/partners/${getPartnerName()}/`;
            dispatch(routerActions.push(`${baseUrl}payment-details/${response.data.paymentDetailsId}`));
        })
        .catch(error => {
            dispatch(notificationsActions.showNotificationError(formatError(error)));
            dispatch(routerActions.push('/'));
        });
};

export default {
    startVerification,
    finishVerification,
};
