exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"colors.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780"
};