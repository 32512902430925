import { handleActions } from 'redux-actions';

import { isDomainUs } from 'common/lib/utils';
import { Currency } from 'common/types/index';
import { CHANGE_FIAT } from './constants';

export interface State {
    fiat: Currency,
}

function getInitialState(): State {
    return {
        fiat: isDomainUs() ? Currency.USD : Currency.EUR,
    };
}

export const reducer = handleActions<State, Currency>(
    {
        [CHANGE_FIAT]: (state, payload): State => {
            return {
                fiat: payload.payload as Currency
            };
        },
    },
    getInitialState(),
);
