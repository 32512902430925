import * as React from 'react';
import { WrappedFieldProps} from 'redux-form';
import classnames from 'classnames';

import TickIcon from './tick.svg';
import ValidationError from './../partials/validation-error';

import * as styles from './checkbox-field.scss';

export interface OwnProps {
    label: string | React.ReactChild;
    disabled?: boolean,
}

type Props = OwnProps & WrappedFieldProps;

export default class CheckboxField extends React.PureComponent<Props> {

    constructor(props: Props) {
        super(props);

        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    toggleCheckbox() {
        const {
            input,
            disabled
        } = this.props;
        if (disabled) return
        if (input.value == 'false' || input.value == false) {
            input.onBlur(true);
        } else {
            input.onBlur(false);
        }

    }

    render() {
        const {
            label,
            input,
            meta,
            disabled
        } = this.props;

        let isChecked: boolean = false;
        if (input.value === undefined || input.value === null) {
            isChecked = false
        } else if (input.value === 'false' || input.value === false) {
            isChecked = false
        } else if (input.value === 'true' || input.value === true){
            isChecked = true;
        }

        const hasError: boolean = meta.touched && !!meta.error;
        const className = classnames(
            styles['checkbox-field'],
            isChecked && styles['is-checked'],
            hasError && styles['has-error'],
            disabled && styles['disabled'],
        );

        return (
            <div className={className} onClick={this.toggleCheckbox}>

                <div className={styles['container']}>
                    <div className={styles['checkbox']}>
                        {isChecked && <TickIcon/>}
                    </div>
                    <div className={styles['label']}>{label}</div>
                </div>

                <ValidationError show={hasError} error={meta.error}/>
            </div>
        );
    }
}
