exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.kyc-page__2hsKX {\n  margin: 32px 0;\n  text-align: center; }\n", "", {"version":3,"sources":["/usr/app/src/client/pages/kyc/components/kyc-page.scss"],"names":[],"mappings":"AAaA;;;;;;EAME;AACF;;;;;;EAME;AACF;EACE,eAAe;EACf,mBAAmB,EAAE","file":"kyc-page.scss","sourcesContent":[":export {\n  background: #f3f4fa;\n  white: #FFFFFF;\n  gray10: #AAACC6;\n  gray20: #E0E2EC;\n  gray30: #F0F1F8;\n  red: #F75959;\n  blue-gray: #717391;\n  primary-color-cryptotraders: #241c4a;\n  secondary-color: #FECF2E;\n  primary-color: #2E304E;\n  secondary-color-changenow: #5C5780; }\n\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n/*\n  Grid sizes based on react-flexbox-grid\n  mobile 576\n  small 768\n  medium 992\n  large 1200\n*/\n.kyc-page {\n  margin: 32px 0;\n  text-align: center; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"background": "#f3f4fa",
	"white": "#FFFFFF",
	"gray10": "#AAACC6",
	"gray20": "#E0E2EC",
	"gray30": "#F0F1F8",
	"red": "#F75959",
	"blue-gray": "#717391",
	"primary-color-cryptotraders": "#241c4a",
	"secondary-color": "#FECF2E",
	"primary-color": "#2E304E",
	"secondary-color-changenow": "#5C5780",
	"kyc-page": "kyc-page__2hsKX"
};