import * as React from 'react';
import classnames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import { WrappedFieldProps } from 'redux-form';

import Typography from 'common/lib/typography/index';
import DropDown from 'common/lib/icons/dropdown.svg';
import { State as PageState } from 'client/pages/teaser/reducer';
import { getPartnerName, isMainPage } from 'common/lib/utils';

import * as styles from './drop-down-field.scss';

interface StateProps {
    page: PageState,
}

export interface OwnProps {
    values: Array<string>,
    title?: string
}

type Props = OwnProps & StateProps & WrappedFieldProps;

interface State {
    show: boolean,
    node: any,
}

class DropDownField extends React.Component<Props, State> {
    public readonly state: State = {
        show: false,
        node: React.createRef(),
    };

    constructor(props: Props) {
        super(props);
        this.show = this.show.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.select = this.select.bind(this);
        this.hide = this.hide.bind(this);
        this.handleClick = this.handleClick.bind(this);
        document.addEventListener('mousedown', this.handleClick);
    }

    handleClick(e: Event) {
        if (this.state.node.current !== null && !this.state.node.current.contains(e.target)) {
            this.hide();
        }
    }

    select(val: string) {
        this.props.input.onChange(val);
        this.setState({ show: false });
    }

    renderOptions() {

        return this.props.values.map(val => {
            return (<a key={val} onClick={() => this.select(val)}><Typography type="text">{val}</Typography></a>);
        });
    }

    show() {
        this.setState({ ...this.state, show: !this.state.show, node: React.createRef() });
    }

    hide() {
        this.setState({ ...this.state, show: false });
    }

    render() {
        const { title } = this.props;
        const contentClassNames = classnames(
            styles['drop-down-content'],
            !isMainPage() && styles[`button-${getPartnerName()}`],
            this.state.show && styles['show'],
        );

        const btnClassNames = classnames(
            styles['drop-down-btn'],
            this.state.show && styles['hide-styles'],
        );
        const isTitleSet = title !== undefined && title != null && title !== '';
        const colSize = isTitleSet ? 9 : 12;

        return (
            <Row>
                {isTitleSet && <Col lg={3} sm={3} xs={3}>
                    <div className={styles['drop-down-title']}>
                        <Typography type="text">{title}</Typography>
                    </div>
                </Col>}
                <Col lg={colSize} sm={colSize} xs={colSize}>
                    <div className={styles['drop-down']} ref={this.state.node}>
                        <div onClick={() => this.show()} className={btnClassNames}>
                            <Row>
                                <Col lg={6} sm={6} xs={6} className={styles['drop-down-btn-text']}>
                                    <Typography type="text">
                                        {this.props.input.value === '' ? 'NONE' : this.props.input.value}
                                    </Typography>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <DropDown/>
                                </Col>
                            </Row>
                        </div>
                        <div id="drop-down-div" className={contentClassNames}>
                            {this.renderOptions()}
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default DropDownField;
