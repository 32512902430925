export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    AED = 'AED',
    WAXP = 'WAXP',
    BTC = 'BTC',
    ETH = 'ETH',
    DEB = 'DEB',
    LEND = 'LEND',
    LRC = 'LRC',
    NANO = 'NANO',
    XNO = 'XNO',
    XVG = 'XVG',
    BCH = 'BCH',
    ICX = 'ICX',
    XMR = 'XMR',
    XZC = 'XZC',
    DAI = 'DAI',
    BNT = 'BNT',
    EOS = 'EOS',
    DASH = 'DASH',
    LTC = 'LTC',
    XRP = 'XRP',
    RVN = 'RVN',
    BNB = 'BNB',
    SLVD = 'SLVD',
    BEAM = 'BEAM',
    ATOM = 'ATOM',
    ETC = 'ETC',
    TRX = 'TRX',
    XLM = 'XLM',
    ZRX = 'ZRX',
}

export enum UserVerificationStatus {
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
    FAILED = 'FAILED',
}

export enum FailReason {
    MISSING_DOCUMENT_PHOTO = 'MISSING_DOCUMENT_PHOTO',
    DOCUMENT_NOT_ACCEPTED = 'DOCUMENT_NOT_ACCEPTED',
    DATA_DOES_NOT_MATCH = 'DATA_DOES_NOT_MATCH',
    SANCTIONED = 'SANCTIONED',
    MISSING_SELFIE_PHOTO = 'MISSING_SELFIE_PHOTO',
    FACES_DOES_NOT_MATCH = 'FACES_DOES_NOT_MATCH',
    POOR_PHOTO_QUALITY = 'POOR_PHOTO_QUALITY',
    POOR_PHOTO_LIGHTING = 'POOR_PHOTO_LIGHTING',
    BLURRED_PHOTO = 'BLURRED_PHOTO',
    BAD_MEDIA_FORMAT = 'BAD_MEDIA_FORMAT',
    MISCELLANEOUS = 'MISCELLANEOUS',
    POSSIBLE_FRAUD_ATTEMPT = 'POSSIBLE_FRAUD_ATTEMPT',
    UNRELATED_PHOTO = 'UNRELATED_PHOTO',
    MORE_THAN_ONE_PERSON = 'MORE_THAN_ONE_PERSON',
    PROHIBITED_COUNTRY_OR_STATE = 'PROHIBITED_COUNTRY_OR_STATE',
    DOCUMENT_IS_EXPIRED = 'DOCUMENT_IS_EXPIRED',
    DOCUMENT_WITH_NON_LATIN_CHARACTERS = 'DOCUMENT_WITH_NON_LATIN_CHARACTERS',
    PART_OF_DOCUMENT_IS_COVERED = 'PART_OF_DOCUMENT_IS_COVERED',
    EXPIRED_IDENTIFICATION = 'EXPIRED_IDENTIFICATION',
    UNDEFINED = 'UNDEFINED'
}

export enum PaymentMethods {
    SIMPLEX = 'SIMPLEX',
    SEPA = 'SEPA',
    VOLT = 'VOLT',
    RAPYD = 'RAPYD',
    OTHERS = 'OTHERS',
}

export enum OfferStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    ACCEPTED = 'ACCEPTED',
    CONFIRMED = 'CONFIRMED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export interface List<P> {
    content: Array<P>
    first: boolean
    last: boolean
    numberOfElements: number
    pageNumber: number
    pageSize: number
    sort: [
        {
            property: string,
            direction: SortDirection,
        }
    ],
    totalElements: number
    totalPages: number
}

export interface Option {
    value: string;
    label: string;
}
