import classnames from 'classnames';
import * as React from 'react';

import * as styles from './typography.scss';

interface Props {
    type: 'header' | 'title' | 'subtitle' | 'text' | 'subtext',
    color?: 'gray' | 'primary' | 'white' | 'secondary',
    inline?: boolean,
}

export default class Typography extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        inline: false,
    };

    render() {
        const {
            type,
            children,
            color,
            inline,
        } = this.props;

        const className = classnames(
            styles['typography'],
            inline && styles['inline'],
            color && styles[color],
            styles[type],
        );

        return (
            <div className={className}>
                {children}
            </div>
        );
    }
}
