import * as React from 'react';
import classnames from 'classnames';

import * as styles from './button.scss';
import { isMainPage, getPartnerName } from '../utils';

interface Props {
    block?: boolean,
    loading?: boolean,
    disabled?: boolean,
    type?: 'submit' | 'button',
    design?: 'white' | 'gray' | 'primary' | 'danger' | 'secondary' | 'text' | 'text-danger',
    color?: 'primary' | 'danger' | 'secondary',
    size?: 'small' | 'default',
    icon?: any,
    preventDefault?: boolean,
    text: string,
    onClick?: () => void,
}

const Button: React.FunctionComponent<Props> = (props) => {
    const {
        text,
        block = false,
        loading = false,
        disabled,
        type = 'submit',
        design = 'secondary',
        color = 'primary',
        icon: Icon,
        preventDefault = false,
        onClick,
        size = 'default',
    } = props;

    const handleClick = (event: React.SyntheticEvent) => {
        if (disabled || loading) {
            return event.preventDefault();
        }

        if (preventDefault && onClick) {
            event.preventDefault();
            onClick();

            return;
        }

        return onClick && onClick();
    };

    const className = classnames(
        styles['button'],
        !isMainPage() && styles[`button-${getPartnerName()}`],
        design && styles[design],
        size && styles[size],
        color && styles[`color-${color}`],
        block && styles['block'],
        disabled && styles['disabled'],
        loading && styles['loading'],
    );

    return (
        <button type={type} className={className} onClick={handleClick}>
            {Icon &&
                <div className={styles['icon']}>
                    <Icon/>
                </div>
            }
            <span className={styles['text']}>{text}</span>
        </button>
    );
};

export default Button;
