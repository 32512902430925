import Button from 'common/lib/button';
import Typography from 'common/lib/typography';
import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { isDomainUs } from 'common/lib/utils';
import * as styles from './error-placeholder.scss';

interface OwnProps {
    onTryAgain?: () => void,
}

type Props = OwnProps & WithNamespaces;

const ErrorPlaceholder = (props: Props) => {
    const {
        t,
        onTryAgain,
    } = props;

    return (
        <div className={styles['error-placeholder']}>
            <Typography type="header">{t('error.oops')}</Typography>
            <Typography type="title">{t('error.UNEXPECTED_ERROR')}</Typography>

            <div className={styles['support-text']}>
                {isDomainUs() ? (<Typography type="text">{t(`error.try_again_or_contact_support_us`)}</Typography>) : (<Typography type="text">{t(`error.try_again_or_contact_support_io`)}</Typography>)}
            </div>

            {onTryAgain &&
                <div className={styles['try-again-button']}>
                    <Button text={t('error.try_again')} onClick={onTryAgain}/>
                </div>
            }
        </div>
    );
};

export default withNamespaces()(ErrorPlaceholder);
