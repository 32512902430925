import * as React from 'react';
import classnames from 'classnames';
import { WrappedFieldProps } from 'redux-form';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { Props as SelectOptionProps } from './select-option';
import Label from './../partials/label';

import * as styles from './select-field.scss';

export interface OwnProps {
    label?: string;
    children: Array<React.ReactElement<SelectOptionProps>>
}

type Props = OwnProps & WrappedFieldProps & WithNamespaces;

interface State {
    visibleOptions: number,
    hasHiddenOptions: boolean,
}

const MAX_INITIAL_OPTIONS = 4;
const INITIAL_OPTIONS = 4;

class SelectOptions extends React.Component<Props, State> {
    public readonly state: State = {
        hasHiddenOptions: this.props.children.length > MAX_INITIAL_OPTIONS,
        visibleOptions: this.props.children.length > MAX_INITIAL_OPTIONS
            ? INITIAL_OPTIONS
            : this.props.children.length,
    };

    constructor(props: Props) {
        super(props);

        this.renderOption = this.renderOption.bind(this);
        this.renderHiddenOption = this.renderHiddenOption.bind(this);
        this.renderVisibleOption = this.renderVisibleOption.bind(this);
        this.renderVisibleOptions = this.renderVisibleOptions.bind(this);
        this.renderHiddenOptions = this.renderHiddenOptions.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.showMoreOptions = this.showMoreOptions.bind(this);

    }

    selectOption(value: string) {
        const {
            input: {
                onChange,
            },
        } = this.props;

        onChange(value);
    }

    showMoreOptions() {
        this.setState({
            hasHiddenOptions: false,
        });
    }

    renderOption(child: React.ReactElement<SelectOptionProps>, animate: boolean) {
        const {
            input: {
                value,
            },
        } = this.props;

        const isSelected = value === child.props.value;

        return React.cloneElement(child, {
            key: child.props.value,
            isSelected,
            onSelectOption: this.selectOption,
        });
    }

    renderVisibleOption(child: React.ReactElement<SelectOptionProps>) {
        return this.renderOption(child, true);
    }

    renderHiddenOption(child: React.ReactElement<SelectOptionProps>) {
        return this.renderOption(child, false);
    }

    renderVisibleOptions() {
        const {
            children,
        } = this.props;

        const {
            visibleOptions,
        } = this.state;

        return React.Children.map(children.slice(0, visibleOptions), this.renderVisibleOption);
    }

    renderHiddenOptions() {
        const {
            children,
        } = this.props;

        const {
            hasHiddenOptions,
            visibleOptions,
        } = this.state;

        const className = classnames(
            styles['hidden-options'],
            hasHiddenOptions && styles['hidden'],
        );

        return (
            <div className={className}>
                {React.Children.map(children.slice(visibleOptions), this.renderHiddenOption)}
            </div>
        );
    }

    componentDidMount() {
        const {
            hasHiddenOptions,
        } = this.state;

        const {
            children,
            input
        } = this.props;

        if (hasHiddenOptions) {
            children.forEach((child, index) => {
                if (child.props.value === input.value && index >= MAX_INITIAL_OPTIONS) {
                    this.showMoreOptions()
                }
            });
        }
    }

    render() {
        const {
            hasHiddenOptions,
        } = this.state;

        const {
            children,
            label,
            t,
        } = this.props;


        return (
            <div className={styles['select-field']}>
                {label &&
                     <Label>{label}</Label>
                }

                <div className={styles['options']}>
                    {this.renderVisibleOptions()}
                    {this.renderHiddenOptions()}
                 </div>

                {hasHiddenOptions &&
                    <div className={styles['show-all-button']} onClick={this.showMoreOptions}>
                        {t('teaser.show_all', {all: children.length})}
                    </div>
                }
            </div>
        );
    }
}

export default withNamespaces()(SelectOptions);
