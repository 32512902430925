import Footer from 'client/components/footer';
import Header from 'client/components/header';
import UserProfile from 'client/components/user';
import * as React from 'react';

import * as styles from './page-with-illustration.scss';

interface Props {
    children: React.ReactChild,
    rightChildren?: React.ReactChild,
    title?: React.ReactChild,
}

export default (props: Props) => {
    const {
        children,
        rightChildren,
        title,
    } = props;

    return (
        <div className={styles['page-with-illustration']}>
            <div className={styles['left']}>
                <div className={styles['header']}>
                    <Header/>
                </div>

                <div className={styles['content']}>
                    {children}
                </div>

                <div className={styles['footer']}>
                    <div className={styles['footer-container']}>
                        <Footer/>
                    </div>
                </div>
                <script id="CookieDeclaration" src="https://consent.cookiebot.com/52d2862a-31f7-4c19-971c-e6733f30f4cc/cd.js" type="text/javascript" async></script>
                {/*<CookiesBar/>*/}
            </div>

            <div className={styles['right']}>
                <div className={styles['user-profile']}>
                    <UserProfile/>
                </div>

                {rightChildren &&
                <div className={styles['children']}>
                    {title}

                    <div className={styles['right-children']}>
                        {rightChildren}
                    </div>
                </div>
                }
            </div>
        </div>
    );
};
