import * as React from 'react';
import classnames from 'classnames';

import * as styles from './alert.scss';

export enum Type {
    FAILED = 'failed',
    VERIFIED = 'verified',
    WARNING = 'warning',
    NORMAL = 'normal',
}

interface Props {
    animate?: boolean,
    type?: Type,
    children: React.ReactChild
}

export default (props: Props) => {
    const className = classnames(
        styles['alert'],
        props.animate && styles['animate'],
        props.type && styles[props.type],
    );

    return <div className={className}>{props.children}</div>;
};
