import classnames from 'classnames';
import * as React from 'react';
import InputMask from 'react-input-mask';
import { WrappedFieldProps } from 'redux-form';

import { default as FieldWrapper, OwnProps as FieldWrapperProps } from './../field-wrapper/field-wrapper';

import * as styles from './text-field.scss';

export interface OwnProps {
    type?: string,
    color?: 'white' | string,
    hideErrors?: boolean,
    mask?: string,
    maskChar?: string,
    autoFocus?: boolean
}

type Props = OwnProps & FieldWrapperProps & WrappedFieldProps;

const TextField = (props: Props) => {

    const {
        placeholder,
        input,
        meta,
        color,
        type = 'text',
        big,
        disabled,
        mask,
        maskChar,
        autoFocus = false,
        ...rest
    } = props;

    const hasError: boolean = meta.touched && !!meta.error;
    const className = classnames(
        styles['text-field'],
        color && styles[color],
        hasError && styles['has-error'],
        disabled && styles['disabled'],
        big && styles['big'],
        mask && styles['masked'],
    );

    return (
        <FieldWrapper hideErrors={hasError} input={input} meta={meta} {...rest} disabled={disabled}>
            {mask
                ? <InputMask
                    mask={mask}
                    maskChar={maskChar}
                    alwaysShowMask={true}
                    className={className}
                    {...input}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoCorrect="Off"
                />
                : <input
                    className={className}
                    {...input}
                    type={type}
                    data-testid={input.name}
                    name={input.name}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    autoCorrect="Off"
                    step="any"
                />
            }
        </FieldWrapper>
    );
};

export default TextField;
