import * as React from 'react';
import classnames from 'classnames';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import Trend from 'react-trend';

import Popover from 'common/lib/popover';
import { formatAmount, parsePair } from 'common/lib/formatter';
import CurrencyIcon from 'common/lib/currency-icon';
import { RenderOptionProps } from 'common/lib/fields';
import { PairModel, PairWrapperModel } from 'client/types';
import {getPartnerName, isMainPage} from 'common/lib/utils';
import UpIcon from './up.svg';
import DownIcon from './down.svg';

import EqualIcon from './equal.svg';
import * as styles from './cryptocurrency-select-option.scss';

interface OwnProps {
    pair: PairWrapperModel,
    pairHistoryPrices: Array<PairModel>,
}

type Props = OwnProps & RenderOptionProps & WithNamespaces;

const getPriceDifferencePercentage = (pairHistoryPrices: Array<PairModel>): number => {
    if (pairHistoryPrices && pairHistoryPrices.length > 1) {
        const lastUnitPrice = pairHistoryPrices[pairHistoryPrices.length - 1].unitPrice;
        const secondLastPrice = pairHistoryPrices[pairHistoryPrices.length - 2].unitPrice;
        const unitPriceDifference = secondLastPrice - lastUnitPrice;

        return (100 * unitPriceDifference) / secondLastPrice;
    }

    return 0;
};

const getPriceHistory = (pairHistoryPrices: Array<PairModel>): Array<number> => {
    if (pairHistoryPrices && pairHistoryPrices.length > 0) {
        return pairHistoryPrices.map(pairHistoryPrice => 1 / pairHistoryPrice.unitPrice);
    }

    return [];
};

const renderPriceDifference = (priceDifference: number, currency: string, t: TranslationFunction) => {
    const decreased = priceDifference < 0;
    const increased = priceDifference > 0;

    return (
        <div className={styles['difference']}>
            {increased && <UpIcon className={styles['arrow-icon']}/>}
            {decreased && <DownIcon className={styles['arrow-icon']}/>}
            {(!decreased && !increased) && <EqualIcon className={styles['arrow-icon']}/>}

            <Popover text={t('teaser.last_hours')}>
                <div className={styles['percentage-container']}>
                    {formatAmount(priceDifference, { precision: 2, withSign: true })}
                    <span className={styles['percentage']}>%</span>
                </div>
            </Popover>
        </div>
    );
};

const DAYS_FOR_GRAPHS = 4;
const DAYS_FOR_DIFFERENCE = 2;

const CryptocurrencySelectOption = (props: Props) => {
    const {
        t,
        isSelected,
        pair,
        pairHistoryPrices,
        value,
        disabled,
    } = props;

    const className = classnames(
        styles['cryptocurrency-select-option'],
        isSelected && styles['is-selected'],
        disabled && styles['disabled'],
    );

    const selectedPair = parsePair(value);
    let unitPrice = 0;
    if (!disabled) {
        unitPrice = 1 / pair.priceList[0].unitPrice;
    }
    const priceDifference = getPriceDifferencePercentage(pairHistoryPrices);
    const priceHistory = getPriceHistory(pairHistoryPrices);
    const showPriceGraphs = priceHistory.length >= DAYS_FOR_GRAPHS;
    const showPriceDifference = priceHistory.length >= DAYS_FOR_DIFFERENCE;

    const iconStyles = classnames(
        styles['currency-icon'],
        styles[selectedPair.getCurrency]);

    const tagClassNames = classnames(
        styles['tag'],
        !isMainPage() && styles[`tag-${getPartnerName()}`],
    );

    return (
        <div className={className}>
            <div className={iconStyles}>
                <CurrencyIcon crypo={selectedPair.getCurrency}/>
            </div>

            <div className={styles['name']}>
                {t(`teaser.crypto_${selectedPair.getCurrency}`)}
            </div>

            {!disabled &&
            <React.Fragment>
                <div className={styles['price']}>
                    {formatAmount(unitPrice)}
                    <span className={styles['currency']}>{selectedPair.giveCurrency}</span>
                </div>

                <div className={styles['history']}>

                    <div className={styles['graph']}>
                        {showPriceGraphs &&
                        <Popover text={t('teaser.last_days')}>
                            <Trend
                                data={priceHistory}
                                smooth
                                radius={5}
                                gradient={[isSelected ? 'white' : '#2E304E']}
                                strokeWidth={5}
                            />
                        </Popover>
                        }

                        {!showPriceGraphs &&
                        <div className={tagClassNames}>{t('teaser.new_coin')}</div>
                        }
                    </div>

                    {showPriceDifference &&
                    renderPriceDifference(priceDifference, selectedPair.giveCurrency, t)
                    }
                </div>
            </React.Fragment>
            }
            {disabled && <span className={styles['maintenance']}>{t('teaser.on_maintainance')}</span>}
        </div>
    );
};

export default withNamespaces()(CryptocurrencySelectOption);
