import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { getPartnerName, isMainPage, scrollToTop } from 'common/lib/utils';
import PartnerPage from 'client/components/partner-page';
import { JumpingDots } from 'common/lib/loaders';

import { parseAmount } from 'common/lib/formatter';
import ErrorPlaceholder from 'client/components/error-placeholder';
import { getPairsPrices, getPairPriceHistory, getUser, sendLoginCode, loginWithCode } from 'client/api';
import { PairsPricesModel, PairsHistoryPricesModel } from 'client/types';
import offerActions from 'client/pages/offer/actions';
import { actions as authActions } from 'client/components/user';
import BuyCryptocurrencyForm, { BuyCryptocurrencyFormData } from './buy-cryptocurrency-form';
import ShareEmailForm, { ShareEmailFormData } from './share-email-form';
import SixDigitsForm, { ShareSixDigitsFormData } from './six-digits-form';

import * as styles from './partner-teaser-page.scss';
import {getPage} from '../selectors';
import {State as PageState} from '../reducer';
import PartnersTeaserContinueComponent from "./partner-teaser-continue-component";
import * as queryString from 'query-string';

enum Steps {
    BUY_FORM,
    SHARE_EMAIL_FOR_CODE,
    SHOW_LOGIN_CODE_FORM,
    SHOW_SAFARI_MESSAGE
}

interface StateProps {
    page: PageState,
}

interface DispatchProps {
    actions: {
        offer: typeof offerActions,
        auth: typeof authActions,
    }
}

type Props = StateProps & DispatchProps & WithNamespaces & RouteComponentProps<any>;

interface State {
    loadingPairs: boolean,
    showError: boolean,
    loginError: string,
    pairs?: PairsPricesModel,
    historyPairs?: PairsHistoryPricesModel,
    currentStep: Steps,
    url: string,
    buyCryptocurrencyForm?: BuyCryptocurrencyFormData,
    userEmail?: string,
    userWallet?: string,
    userMemo?: string,
}

class PartnerTeaserPage extends React.Component<Props, State> {
    public readonly state: State = {
        loadingPairs: true,
        showError: false,
        url: undefined,
        loginError: null,
        currentStep: Steps.BUY_FORM,
    };

    constructor(props: Props) {
        super(props);

        this.showBuyForm = this.showBuyForm.bind(this);
        this.buyCrypto = this.buyCrypto.bind(this);
        this.sendLoginCode = this.sendLoginCode.bind(this);
        this.loadPairs = this.loadPairs.bind(this);
        this.loadHistoryPrice = this.loadHistoryPrice.bind(this);
        this.renderBuyForm = this.renderBuyForm.bind(this);
        this.renderCurrentStep = this.renderCurrentStep.bind(this);
        this.shareEmailForCode = this.shareEmailForCode.bind(this);
        this.shareEmailForCode = this.shareEmailForCode.bind(this);
        this.loginWithCode = this.loginWithCode.bind(this);
        this.resendEmail = this.resendEmail.bind(this);

        localStorage.setItem('partner', props.match.params.partner);
    }

    componentDidMount() {
        scrollToTop();

        this.loadHistoryPrice();
        this.loadPairs();

        if (!!this.props.match.params['step'] && !!this.props.match.params['email']) {
            this.setState({...this.state,
                userEmail: atob(this.props.match.params['email']),
                currentStep: Number.parseInt(this.props.match.params['step'])})
        }

        const {
            location: {
                search,
            },
        } = this.props;

        const {
            wallet,
            memo,
            values
        } = queryString.parse(search);

        if (values != null) {
            const formData = JSON.parse(atob(values as string)) as BuyCryptocurrencyFormData;
            if (!!formData) {
                this.setState({...this.state, buyCryptocurrencyForm: formData, currentStep: 1});
                this.buyCrypto(formData);
            }
        }



        this.setState({...this.state, userWallet: wallet as string, userMemo: memo as string});
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.page.fiat !== prevProps.page.fiat) {
            this.loadHistoryPrice();
            this.loadPairs();
        }
    }

    showBuyForm() {
        this.loadPairs();
        this.setState({
            buyCryptocurrencyForm: null,
            currentStep: Steps.BUY_FORM,
        });
    }

    loadHistoryPrice() {
        getPairPriceHistory()
            .then(response => {
                this.setState({
                    historyPairs: response.data,
                });
            });
    }

    loadPairs() {
        this.setState({
            loadingPairs: true,
            showError: false,
        });
        getPairsPrices(this.props.page.fiat)
            .then(response => {
                const pairs = response.data;

                this.setState({
                    loadingPairs: false,
                    pairs,
                });
            })
            .catch(() => {
                this.setState({
                    loadingPairs: false,
                    showError: true,
                });
            });
    }

    loginWithCode(values: ShareSixDigitsFormData): void {
        const {
            actions,
            t,
        } = this.props;

        const {
            userWallet,
            userMemo,
        } = this.state;

        // @ts-ignore
        return loginWithCode(this.state.userEmail, values.digits)
            .then(response => {
                const {
                    data: {
                        payload: {
                            action,
                            amount,
                            pair,
                        },
                    },
                } = response;
                return actions.offer.createOffer(pair, action, amount, amount, userWallet, userMemo);
            })
            .catch(() => {
                throw new SubmissionError({
                    digits: t('error.wrong_sixdigits'),
                    _error: t('error.login_error'),
                });
            });
    }

    shareEmailForCode(values: ShareEmailFormData, recaptcha: string) {
        return this.sendLoginCode(values.email, recaptcha, this.state.buyCryptocurrencyForm);
    }

    resendEmail(recaptcha: string) {
        return this.sendLoginCode(this.state.userEmail, recaptcha, this.state.buyCryptocurrencyForm);
    }

    buyCrypto(values: BuyCryptocurrencyFormData) {
        const {
            actions,
        } = this.props;

        const {
            userWallet, userMemo,
        } = this.state;

        return getUser()
            .then(() => {
                return actions.offer.createOffer(values.pair, values.action, values.giveAmount, values.getAmount, userWallet, userMemo);
            })
            .catch(() => {
                scrollToTop();
                actions.auth.clearUser();

                this.setState({
                    buyCryptocurrencyForm: values,
                    currentStep: Steps.SHARE_EMAIL_FOR_CODE,
                    userWallet,
                    userMemo,
                });
            });
    }

    isInIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    sendLoginCode(email: string, recaptcha: string, buyCryptocurrencyForm: BuyCryptocurrencyFormData) {
        const {
            pair,
            action,
            giveAmount,
            getAmount,
        } = buyCryptocurrencyForm;

        const {
            userWallet, userMemo
        } = this.state;

        const amount = parseAmount(action === 'GIVE' ? giveAmount : getAmount);
            return sendLoginCode(email, pair, action, amount, recaptcha, userWallet, userMemo)
            .then(() => {
                scrollToTop();

                var ua = navigator.userAgent.toLowerCase();
                let params = '';
                if (!!userMemo && !!userWallet) {
                    params = `?wallet=${userWallet}&memo=${userMemo}`;
                }

                if (this.isInIframe() && ua.indexOf('safari') != -1 && ua.indexOf('chrome') == -1) {
                    this.setState({
                        url: `/partners/${getPartnerName()}/login/2/${btoa(email)}${params}`,
                        userEmail: email,
                        currentStep: Steps.SHOW_SAFARI_MESSAGE,
                    });
                } else {
                    this.setState({
                        userEmail: email,
                        currentStep: Steps.SHOW_LOGIN_CODE_FORM,
                    });
                }
            })
            .catch(() => {
                scrollToTop();
                this.setState({
                    showError: true,
                });
            });
    }

    renderBuyForm() {
        const {
            loadingPairs,
            pairs,
            historyPairs,
        } = this.state;

        if (loadingPairs) {
            return <JumpingDots dark/>;
        }

        const showUserSteps = isMainPage() ? true : getPartnerName() !== 'bancor';


        return (
            <BuyCryptocurrencyForm
                pairs={pairs}
                historyPairs={historyPairs}
                initialValues={
                    {
                        pair: pairs.priceOrder[0],
                    }
                }
                showUserSteps={showUserSteps}
                onSubmit={this.buyCrypto}
            />
        );
    }

    renderCurrentStep() {
        const {
            currentStep,
            userEmail,
            showError,
            userMemo,
            userWallet,
        } = this.state;

        const {
            t,
        } = this.props;

        if (showError) {
            return <ErrorPlaceholder onTryAgain={this.loadPairs}/>;
        }

        if (currentStep === Steps.BUY_FORM) {
            return this.renderBuyForm();
        }

        if (currentStep === Steps.SHOW_SAFARI_MESSAGE) {
            return (
                <PartnersTeaserContinueComponent url={this.state.url} userWallet={userWallet} userMemo={userMemo}/>
            );
        }

        if (currentStep === Steps.SHARE_EMAIL_FOR_CODE) {
            return (
                <ShareEmailForm
                    onFormSubmit={this.shareEmailForCode}
                    onClickBack={this.showBuyForm}
                    titleText={t('share_email.six_digits_code')}
                    subtitleText={t('share_email.six_digits_code_subtitle')}
                    button={t('share_email.send_digits_code')}
                    initialValues={{
                        email: userEmail,
                    }}
                    magic={false}
                />
            );
        }

        if (currentStep === Steps.SHOW_LOGIN_CODE_FORM) {

            return (
                <SixDigitsForm
                    onResendCode={this.resendEmail}
                    onClickBack={this.showBuyForm}
                    onFormSubmit={this.loginWithCode}
                />
            );
        }

        return null;
    }

    render() {
        return (
            <div className={styles['partner-teaser-page']}>
                <PartnerPage userWallet={this.state.userWallet} userMemo={this.state.userMemo} validate>
                    {this.renderCurrentStep()}
                </PartnerPage>
            </div>
        );
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        page: getPage(state)
    })
};


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
        offer: bindActionCreators(offerActions, dispatch),
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PartnerTeaserPage));
