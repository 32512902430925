import * as i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import { formatAmount } from 'common/lib/formatter';
import en from './translations/en.json';

const defaultLanguage = 'en';

i18n
    .use(reactI18nextModule)
    .init({
        interpolation: {
            format(value, format) {
                if (format === 'fiat') {
                    return formatAmount(value, {
                        precision: 2,
                        currency: '',
                    });
                }

                return value;
            },
        },
        resources: {
            en: { translation: en },
        },
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        returnEmptyString: false,
        keySeparator: false,
        react: {
            wait: true,
        },
    });

export default i18n;
